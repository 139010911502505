import { uniq } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import getPlaybackStream, { PlaybackStreamUnauthorisedError } from '@@src/apis/PlaybackApi';
import { useAppDispatch, useAppSelector, useAppStore } from '@@src/hooks/store';
import { SbsUIVariant } from '@@src/lib/VideoPlayerV2/BitmovinPlayerUI/components/SbsUIContainer';
import OztamTracking from '@@src/lib/VideoPlayerV2/plugins/OztamTracking/OztamTracking';
import { getUserPreferences } from '@@src/lib/VideoPlayerV2/plugins/SaveUserPreferences/SaveUserPreferences';
import { generatePathFromLinkProps } from '@@src/routes';
import { getResumePosition, loadProgressesOnce } from '@@stores/ProgressStore';
import { getServerSideRendered } from '@@stores/SettingsStore';
import { getVideoAsyncThunk } from '@@stores/VideoStore';
import OnDemand from '@@types/OnDemand';
import { PlaybackStreamData } from '@@types/PlaybackStreamData';
import Logger from '@@utils/logger/Logger';

const enableOztam = typeof process.env.BVAR_OZTAM_ENV !== 'undefined';

const useGetVideoData = (
  videoId: string,
  language: string,
  userId: string | null,
) => {
  const [data, setData] = useState<{
    playbackStreamData?: PlaybackStreamData;
    video?: OnDemand.Video;
    ozTamSessionId?: string;
    resumePosition?: number;
    uiVariants?: Record<string, SbsUIVariant>;
    closePath?: string;
    error?: Error;
  }>({});

  const dispatch = useAppDispatch();
  const store = useAppStore();

  const history = useHistory();
  const serverSideRendered = useAppSelector(getServerSideRendered);

  // using ref because we don't want videoIds to trigger a re-render
  const videoIds = useRef<string[]>([]);

  useEffect(() => {
    videoIds.current = uniq([...videoIds.current, videoId]);
  }, [videoId]);

  const getClosePath = useCallback((_video: OnDemand.Video) => {
    let path = '';
    if (_video && (serverSideRendered || history.length === 1 || videoIds.current.length > 1)) {
      // go to details page if the page was server side rendered, there is no history, or we have been playing multiple videos
      path = generatePathFromLinkProps(_video.route);

      // if the video route is the watch page (Eg: clips don't have a details page), go to home page
      if (_video.route.name === 'watch') {
        path = '/';
      } else if (_video.type === 'Episode' && _video.episodeData) {
        // if video is an episode, go to the series details page
        path = generatePathFromLinkProps(_video.episodeData.seriesRoute);
      }
    }
    return path;
  }, [history.length, serverSideRendered]);

  useEffect(() => {
    // if videoId is not a number, we know that it's not valid, so don't even try to call the API
    if (videoId && !/^\d+$/.exec(videoId)) {
      return;
    }

    // need to wait until the user is set before loading the video
    if (userId === undefined) {
      return;
    }

    const ozTamSessionId = enableOztam ? OztamTracking.generateSessionId() : undefined;
    const userPrefs = getUserPreferences();

    const playbackStreamPromise = getPlaybackStream(
      videoId,
      language,
      {
        telariaId: window.tvid_cookie_id,
        ozTamSessionId,
        ...(userPrefs?.stLang && { subtitle: userPrefs.stLang }),
      },
    );

    const videoPromise = dispatch(getVideoAsyncThunk(videoId, language));

    const resumePositionPromise = dispatch(loadProgressesOnce())
      .then(() => {
        return getResumePosition(store.getState(), videoId);
      });

    Promise.allSettled([playbackStreamPromise, videoPromise, resumePositionPromise])
      .then(([playbackStreamData, video, resumePosition]) => {
        // if playback not available because of unauthorised, we want to return the video data
        if (playbackStreamData.status === 'rejected' && playbackStreamData.reason instanceof PlaybackStreamUnauthorisedError) {
          setData({
            video: video.status === 'fulfilled' ? video.value : null,
            error: playbackStreamData.reason,
          });
          return;
        }

        if (playbackStreamData.status === 'rejected') {
          // if playback is not available for other reasons, throw the error
          throw playbackStreamData.reason;
        }

        if (video.status === 'rejected') {
          throw video.reason;
        }

        setData({
          playbackStreamData: playbackStreamData.status === 'fulfilled' ? playbackStreamData.value : null,
          video: video.value,
          ozTamSessionId,
          resumePosition: resumePosition.status === 'fulfilled' ? resumePosition.value : null,
          closePath: getClosePath(video.value),
        });
      })
      .catch((e) => {
        Logger.error(e);
        setData({ error: e });
      });
  }, [videoId, language, dispatch, store, getClosePath, userId]);

  return data;
};

export default useGetVideoData;
