/**
 * ConvivaAnalytics plugin
 * Based on: https://github.com/bitmovin/bitmovin-player-web-analytics-conviva/blob/v4.0.3/src/ts/ConvivaAnalytics.ts
 * Using generic Conviva HTML5 Player module, a lot of metadata and player state changes are auto-collected.
 * SDK documentations:
 * https://pulse.conviva.com/learning-center/content/sensor_developer_center/sensor_integration/javascript/javascript_stream_sensor.htm
 *
 */
import Conviva, { AdAnalytics, ConvivaMetadata, VideoAnalytics } from '@convivainc/conviva-js-coresdk';
import ConvivaGoogledaiModule from '@convivainc/conviva-js-daisdk';
import ConvivaHtml5Module from '@convivainc/conviva-js-html5';
import { StreamType as BitmovinStreamType } from 'bitmovin-player';
import { get } from 'lodash';
import { DateTime } from 'luxon';
import { isDesktop, isMobileOnly, isTablet } from 'react-device-detect';

import '@@src/lib/VideoPlayer/Vendor/OzTAMService.min';
import GoogleDaiStreamHandler from '@@src/lib/VideoPlayerV2/StreamHandlers/GoogleDaiStreamHandler';
import {
  VideoPlayerErrorEvent,
  VideoPlayerEventCallback,
  VideoPlayerEventType,
  VideoPlayerPlaybackQualityChangedEvent,
} from '@@src/lib/VideoPlayerV2/VideoPlayerEventManager';
import OnDemand from '@@types/OnDemand';
import { VERSION } from '@@utils/constants';
import Logger from '@@utils/logger/Logger';

import VideoPlayer, { TimeMode, SourceConfig } from '../../VideoPlayer';
import { ContentMetadataBuilder, Metadata, StreamType } from './ContentMetadataBuilder';

export type ConvivaEnvironmentTypes = 'test' | 'touchstone' | 'prod';

export interface ConvivaAnalyticsOptions {
  assetName: string,
  video: OnDemand.Video,
  streamUrl: string,
  userId: string | null,
  env: ConvivaEnvironmentTypes | undefined,
}

export interface ConvivaAnalyticsConfiguration {
  customerKey: string;

  /**
   * Enables debug logging when set to true (default: false).
   */
  debugLoggingEnabled?: boolean;
  /**
   * The TOUCHSTONE_SERVICE_URL for testing with Touchstone. Only to be used for development, must not be set in
   * production or automated testing.
   */
  gatewayUrl?: string;
}

export default class ConvivaAnalytics {
  private pluginVersion: string = '4.0.0';
  private videoPlayer: VideoPlayer;
  private config: ConvivaAnalyticsConfiguration;
  private contentMetadataBuilder: ContentMetadataBuilder;
  private videoAnalytics: VideoAnalytics | null;
  private adAnalytics: AdAnalytics | null;
  private video: OnDemand.Video;
  private videoHtmlElement: HTMLVideoElement;
  private userId: string | '';
  private assetName: string;

  constructor(videoPlayer: VideoPlayer, options: ConvivaAnalyticsOptions) {
    const env = options.env ?? 'test';

    this.videoPlayer = videoPlayer;
    this.videoHtmlElement = videoPlayer.getVideoElement();
    this.video = options.video;
    this.userId = options.userId || '';
    this.config = this.generateConfig(env);
    this.videoAnalytics = null;
    this.adAnalytics = null;
    this.assetName = options.assetName;

    const settings = {} as { [key: string]: string | number };
    if (env !== 'prod') {
      settings[Conviva.Constants.GATEWAY_URL] = get(this.config, 'gatewayUrl', '');
      settings[Conviva.Constants.LOG_LEVEL] = this.config.debugLoggingEnabled ? Conviva.Constants.LogLevel.DEBUG : Conviva.Constants.LogLevel.NONE;
    }

    Conviva.Analytics.init(this.config.customerKey, null, settings);

    this.contentMetadataBuilder = new ContentMetadataBuilder();
    this.contentMetadataBuilder.streamUrl = options.streamUrl;
    this.setMetadataOverrides(videoPlayer.playerName);

    this.registerPlayerEvents();

    try {
      this.requestNewSession();
    } catch (error) {
      if (error instanceof Error) {
        Logger.error(error.message);
      }
    }
  }

  private registerPlayerEvents = (): void => {
    this.videoPlayer.on(VideoPlayerEventType.SOURCE_LOADED, this.onSourceLoaded as VideoPlayerEventCallback);
    this.videoPlayer.on(VideoPlayerEventType.VIDEO_PLAYBACK_QUALITY_CHANGED, this.onVideoPlaybackQualityChanged as VideoPlayerEventCallback);
    this.videoPlayer.on(VideoPlayerEventType.SOURCE_UNLOADED, this.onSourceUnloaded);
    this.videoPlayer.on(VideoPlayerEventType.PLAYING, this.onPlaying);
    this.videoPlayer.on(VideoPlayerEventType.PLAYBACK_FINISHED, this.onPlaybackFinished);
    this.videoPlayer.on(VideoPlayerEventType.PLAYER_ERROR, this.onPlayerError as VideoPlayerEventCallback);
    this.videoPlayer.on(VideoPlayerEventType.PLAYER_WARNING, this.onPlayerWarning as VideoPlayerEventCallback);
    this.videoPlayer.on(VideoPlayerEventType.AD_STARTED, this.onAdStarted);
    this.videoPlayer.on(VideoPlayerEventType.AD_BREAK_FINISHED, this.onAdBreakFinished);
  };

  private unregisterPlayerEvents = (): void => {
    this.videoPlayer.off(VideoPlayerEventType.SOURCE_LOADED, this.onSourceLoaded as VideoPlayerEventCallback);
    this.videoPlayer.off(VideoPlayerEventType.VIDEO_PLAYBACK_QUALITY_CHANGED, this.onVideoPlaybackQualityChanged as VideoPlayerEventCallback);
    this.videoPlayer.off(VideoPlayerEventType.SOURCE_UNLOADED, this.onSourceUnloaded);
    this.videoPlayer.off(VideoPlayerEventType.PLAYING, this.onPlaying);
    this.videoPlayer.off(VideoPlayerEventType.PLAYBACK_FINISHED, this.onPlaybackFinished);
    this.videoPlayer.off(VideoPlayerEventType.PLAYER_ERROR, this.onPlayerError as VideoPlayerEventCallback);
    this.videoPlayer.off(VideoPlayerEventType.PLAYER_WARNING, this.onPlayerWarning as VideoPlayerEventCallback);
  };

  private onSourceLoaded = (): void => {
    const source = this.videoPlayer.getSource();
    if (source) {
      this.setSourceRelatedMetadata(source);
    }

    this.updateSession();
  };

  private convertBitrate = (bitrate: number) => {
    // We calculate the bitrate with a divisor of 1000 so the values look nicer
    // Example: 250000 / 1000 => 250 kbps (250000 / 1024 => 244kbps)
    return Math.round(bitrate / 1000);
  };

  private onVideoPlaybackQualityChanged = (event: VideoPlayerPlaybackQualityChangedEvent) => {
    const { bitrate, isAd } = event;

    const bitrateKbps = this.convertBitrate(bitrate);

    if (isAd && this.adAnalytics) {
      this.adAnalytics.reportAdMetric(Conviva.Constants.Playback.BITRATE, bitrateKbps);
    }
    if (!isAd && this.videoAnalytics) {
      this.videoAnalytics.reportPlaybackMetric(Conviva.Constants.Playback.BITRATE, bitrateKbps);
    }
  };

  private onSourceUnloaded = () => {
    if (this.videoAnalytics) {
      this.videoAnalytics.reportPlaybackEnded();
    }

    this.endSession(true);
    this.resetMetadata();
    this.unregisterPlayerEvents();
  };

  private onAdStarted = () => {
    const bitrate = this.videoPlayer.getCurrentBitrate();
    if (bitrate && this.adAnalytics) {
      this.adAnalytics.reportAdMetric(Conviva.Constants.Playback.BITRATE, this.convertBitrate(bitrate));
    }
  };

  private onAdBreakFinished = () => {
    const bitrate = this.videoPlayer.getCurrentBitrate();
    if (bitrate && this.videoAnalytics) {
      this.videoAnalytics.reportPlaybackMetric(Conviva.Constants.Playback.BITRATE, this.convertBitrate(bitrate));
    }
  };

  private onPlaying = () => {
    this.contentMetadataBuilder.setPlaybackStarted(true);
    this.updateSession();
  };

  private onPlaybackFinished = () => {
    // End session but don't release SDK in case the user does not leave the player and plays again
    this.endSession();
  };

  // Is used to report only Errors (with FATAL severity) within a Conviva Session
  // and the respective Conviva session can be optionally terminated if the playback cannot be recovered.
  public reportError = (message: string, isAd: boolean = false, shouldEndSession: boolean = false) => {
    if (isAd && this.adAnalytics) {
      this.adAnalytics.reportAdError(message, Conviva.Constants.ErrorSeverity.FATAL);
    }

    if (this.videoAnalytics) {
      this.videoAnalytics.reportPlaybackError(message + (isAd ? ' (during ad playback)' : ''), Conviva.Constants.ErrorSeverity.FATAL);
    }

    if (shouldEndSession) {
      this.endSession(true);
      this.resetMetadata();
      this.unregisterPlayerEvents();
    }
  };

  // Is used to report only Errors (with WARNING severity) within a Conviva Session
  // and the respective Conviva session remains active.
  public reportWarning = (message: string, isAd: boolean = false) => {
    if (isAd && this.adAnalytics) {
      this.adAnalytics.reportAdError(message, Conviva.Constants.ErrorSeverity.WARNING);
    }

    if (this.videoAnalytics) {
      this.videoAnalytics.reportPlaybackError(message + (isAd ? ' (during ad playback)' : ''), Conviva.Constants.ErrorSeverity.WARNING);
    }
  };

  /**
   * reportAdFailed/reportPlaybackFailed - has multiple features:
   * If we call this API, then sensor will assume that a fatal error has occurred and it will then report
   *   the fatal error to Conviva and immediately after this, end the respective session. If you have passed
   *   the Content Metadata dictionary while calling this API, then that will be updated in the session before ending it.
   * If there is no Active Conviva session and we try to call this API, then it has a feature to Create
   *   a new Ad/Content session with the Ad/Content Metadata dictionary passed and then report the Fatal Error
   *   in that session and finally, end that session.
   * @param message
   * @param isAd
   * @param metadata
   */
  public reportPlaybackFailed = (message: string, isAd: boolean = false, metadata?: ConvivaMetadata) => {
    if (isAd && this.adAnalytics) {
      this.adAnalytics.reportAdFailed(message);
    }

    if (this.videoAnalytics) {
      this.videoAnalytics.reportPlaybackFailed(message + (isAd ? ' (during ad playback)' : ''), metadata || this.contentMetadataBuilder.build());
    }
  };

  private onPlayerError = (event: VideoPlayerErrorEvent) => {
    const {
      isAd, name, code, message, data,
    } = event;

    // If there was an error before video has loaded, chances are that the Video Analytics plugin has not been
    // instantiated and no session has started. So we we are requesting a new one here in order to be able
    // to report the error.
    if (!this.videoAnalytics) {
      this.requestNewSession();
    }

    const reason = data?.reason;

    if (code === 'SE-001') {
      const httpRequestRetryCount: number | undefined = data?.httpRequestRetryCount;
      const requestRetryMaxed = httpRequestRetryCount ? httpRequestRetryCount >= 3 : false;

      this.reportError(
        message,
        isAd,
        this.video.isLiveStream && requestRetryMaxed,
      );
    } else if (reason) {
      this.reportPlaybackFailed(
        `Player error ${reason} [${code}][${name}] ${message}`,
        isAd,
        this.contentMetadataBuilder.build(),
      );
    } else {
      this.reportPlaybackFailed(
        `Player error [${code}][${name}] ${message}`,
        isAd,
        this.contentMetadataBuilder.build(),
      );
    }
  };

  private onPlayerWarning = (event: VideoPlayerErrorEvent) => {
    const {
      isAd, name, code, message,
    } = event;

    if (code === 'SW-003') {
      this.reportWarning(message);
    } else {
      this.reportWarning(`Player warning [${code}][${name}] ${message}`, isAd);
    }
  };

  private resetMetadata = () => {
    this.contentMetadataBuilder.reset();
  };

  public reportAppEvent = (eventType: string, eventDetail?: { [key: string]: string }) => {
    Conviva.Analytics.reportAppEvent(eventType, eventDetail);
  };

  public generateConfig = (env: ConvivaEnvironmentTypes | undefined = 'test') => {
    const convivaConfigs = {
      test: {
        customerKey: 'd793177943f56c9e4795b0ba62b873c61132016f',
        gatewayUrl: 'https://sbs-test.testonly.conviva.com',
      },
      touchstone: {
        customerKey: 'b800410803a4659ffcd7b9b6577a907110b7aa21',
        gatewayUrl: 'https://sbs.testonly.conviva.com',
      },
      prod: {
        customerKey: 'b800410803a4659ffcd7b9b6577a907110b7aa21',
        gatewayUrl: 'https://cws.conviva.com',
      },
    };

    let enableConvivaDebugging = false;
    if (env === 'touchstone') {
      enableConvivaDebugging = true;
    }

    const config = get(convivaConfigs, env, convivaConfigs.prod);
    return {
      customerKey: config.customerKey,
      debugLoggingEnabled: enableConvivaDebugging,
      gatewayUrl: config.gatewayUrl,
    };
  };

  public setMetadataOverrides = (applicationName: string) => {
    const pubDate = get(this.video, 'airDate', get(this.video, 'availableDate'));

    let streamType: StreamType = Conviva.Constants.StreamType.VOD;
    let contentType = 'VOD';
    let isLive = 'false';

    if (this.video.isLiveStream) {
      streamType = Conviva.Constants.StreamType.LIVE;
      contentType = 'Live';
      isLive = 'true';
    }

    const contentMetadata: Metadata = {
      viewerId: this.userId,
      assetName: this.assetName,
      streamType,
      duration: this.video.duration,
      applicationName,
      custom: {
        playerVendor: 'Bitmovin',
        playerVersion: this.videoPlayer.version,
        ...(pubDate && {
          pubDate: DateTime.fromISO(pubDate).toFormat('LLL dd, yyyy'),
        }),
        'c3.cm.id': this.video.id,
        'c3.cm.contentType': contentType,
        'c3.cm.channel': this.video.channels[0],
        'c3.cm.categoryType': this.video.type,
        'c3.cm.genre': this.video.genres[0],
        'c3.cm.genreList': this.video.genres.join(', '),
        ...(VERSION && { 'c3.app.version': VERSION }),
        isLive,
        contentLength: this.video.duration === null ? '0' : this.video.duration.toString(),
      },
    };

    if ('episodeData' in this.video && this.video.episodeData) {
      contentMetadata.custom = {
        ...contentMetadata.custom,
        'c3.cm.showTitle': this.video.title,
        'c3.cm.episodeNumber': this.video.episodeData.episodeNumber.toString(),
        'c3.cm.seasonNumber': this.video.episodeData.seasonNumber.toString(),
        'c3.cm.seriesName': this.video.episodeData.programName,
      };
    } else {
      contentMetadata.custom = {
        ...contentMetadata.custom,
        'c3.cm.showTitle': this.video.title,
      };
    }

    this.contentMetadataBuilder.setOverrides(contentMetadata);
  };

  private requestNewSession = (): void => {
    // Check if there is an existing session
    if (this.videoAnalytics) {
      return;
    }

    this.initializeSession();
  };

  /**
   * A Conviva Session should only be initialized when there is a source provided in the player because
   * Conviva only allows to update different `contentMetadata` only at different times.
   *
   * The session should be created as soon as there was a play intention from the user.
   *
   * Set only once:
   *  - assetName
   *
   * Update before first video frame:
   *  - viewerId
   *  - streamType
   *  - playerName
   *  - duration
   *  - custom
   *
   * Multiple updates during session:
   *  - streamUrl
   *  - defaultResource (unused)
   *  - encodedFrameRate (unused)
   */
  private initializeSession = () => {
    const streamHandler = this.videoPlayer.getStreamHandler();

    const customMetadata: Record<string, string> = {};

    if (streamHandler instanceof GoogleDaiStreamHandler) {
      customMetadata.googleDaiStreamId = streamHandler.getStreamId() || '';
    }

    this.setContentMetadata(customMetadata);

    const ConvivaOptions = {
      [Conviva.Constants.CONVIVA_MODULE]: ConvivaHtml5Module,
    };
    this.videoAnalytics = Conviva.Analytics.buildVideoAnalytics();
    if (!this.videoAnalytics) {
      // Something went wrong. With stable system interfaces, this should never happen.
      throw new Error('ConvivaAnalytics: Something went wrong');
    }

    if (streamHandler instanceof GoogleDaiStreamHandler) {
      this.adAnalytics = Conviva.Analytics.buildAdAnalytics(this.videoAnalytics);
      const extraListeners = {
        [Conviva.Constants.IMASDK_CONTENT_PLAYER]: this.videoPlayer.getVideoElement(),
        [Conviva.Constants.CONVIVA_MODULE]: ConvivaGoogledaiModule,
      };

      // This API is used to initialise the IMA DAI module and registers listeners for ad playback
      this.adAnalytics.setAdListener(streamHandler.getStreamManager(), extraListeners);
    }

    let deviceType;
    switch (true) {
      case isMobileOnly:
        deviceType = Conviva.Constants.DeviceType.MOBILE;
        break;

      case isTablet:
        deviceType = Conviva.Constants.DeviceType.TABLET;
        break;

      case isDesktop:
      default:
        deviceType = Conviva.Constants.DeviceType.DESKTOP;
        break;
    }

    const deviceMetadata = {
      [Conviva.Constants.DeviceMetadata.TYPE]: deviceType,
      [Conviva.Constants.DeviceMetadata.CATEGORY]: Conviva.Constants.DeviceCategory.WEB,
    };
    Conviva.Analytics.setDeviceMetadata(deviceMetadata);

    const contentMetadata = this.contentMetadataBuilder.build();
    this.videoAnalytics.reportPlaybackRequested(contentMetadata);
    this.videoAnalytics.setCallback(() => {
      const playheadTimeMs = this.videoPlayer.getCurrentTime(TimeMode.RelativeTime) * 1000;
      if (this.videoAnalytics) {
        this.videoAnalytics.reportPlaybackMetric(
          Conviva.Constants.Playback.PLAY_HEAD_TIME,
          playheadTimeMs,
        );
      }
    });

    // @ts-ignore: Conviva have forgotten to add support of CONVIVA_MODULE option key in their typescript file
    this.videoAnalytics.setPlayer(this.videoHtmlElement, ConvivaOptions);
    this.videoAnalytics.reportPlaybackMetric(Conviva.Constants.Playback.PLAYER_STATE, Conviva.Constants.PlayerState.STOPPED);
  };

  private endSession = (releaseSdk: boolean = false) => {
    if (this.adAnalytics) {
      this.adAnalytics.release();
      this.adAnalytics = null;
    }

    if (this.videoAnalytics) {
      this.videoAnalytics.release();
      this.videoAnalytics = null;
    }

    if (releaseSdk) Conviva.Analytics.release();
  };

  private updateSession = () => {
    if (!this.videoAnalytics) {
      return;
    }

    const streamHandler = this.videoPlayer.getStreamHandler();
    if (streamHandler instanceof GoogleDaiStreamHandler) {
      this.contentMetadataBuilder.custom = {
        ...this.contentMetadataBuilder.custom,
        googleDaiStreamId: streamHandler.getStreamId() || '',
      };
    }

    const metadata = this.contentMetadataBuilder.build();
    this.videoAnalytics.setContentInfo(metadata);
  };

  private getUrlFromSource = (source: SourceConfig): string | undefined => {
    const sourceType = this.videoPlayer.getStreamType();
    if (sourceType !== BitmovinStreamType.Unknown && sourceType !== BitmovinStreamType.Progressive) {
      return source[sourceType];
    }

    throw new Error(`ConvivaAnalytics: Source type unsupported (${sourceType})`);
  };

  private setSourceRelatedMetadata = (source: SourceConfig) => {
    const streamUrl = this.getUrlFromSource(source);
    if (streamUrl) {
      this.contentMetadataBuilder.streamUrl = streamUrl;
    }

    this.contentMetadataBuilder.custom = {
      ...this.contentMetadataBuilder.custom,
      playerType: this.videoPlayer.getPlayerType(),
      streamType: this.videoPlayer.getStreamType(),
    };
  };

  private setContentMetadata = (customMetadata: Record<string, string> = {}) => {
    this.contentMetadataBuilder.duration = this.videoPlayer.getDuration();
    this.contentMetadataBuilder.streamType = this.video.isLiveStream ? Conviva.Constants.StreamType.LIVE : Conviva.Constants.StreamType.VOD;

    this.contentMetadataBuilder.custom = {
      // Autoplay and preload are important options for the Video Startup Time so we track it as custom tags
      autoplay: this.videoPlayer.getAutoplayConfig().toString(),
      preload: this.videoPlayer.getPreloadConfig().toString(),
      integrationVersion: this.pluginVersion,
      streamProtocol: 'https',
      connectionType: 'N/A',
      ...customMetadata,
    };

    const source = this.videoPlayer.getSource();
    // This could be called before we got a source
    if (source) {
      this.setSourceRelatedMetadata(source);
    }
  };

  public unload() {
    this.onSourceUnloaded();
  }
}
