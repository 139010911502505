// https://developers.this.google.com/interactive-media-ads/docs/sdks/html5/dai/quickstart
import { ConvivaAnalytics } from '@bitmovin/player-integration-conviva';
import { ControlBar, UIManager } from '@sbs/bitmovin-player-ui';
import { Player, PlayerAPI } from 'bitmovin-player';
import * as ls from 'local-storage';
import { find, get, debounce } from 'lodash';
import { isDesktop, isMobile } from 'react-device-detect';

import { PlayerEventsOptions } from '@@src/lib/VideoPlayer/PlayerEvents';
import VideoStream from '@@types/VideoStream';
import { PlayerUserSettings } from '@@utils/DataLayer';
import { getSbsLoginInstance } from '@@utils/SbsLoginUtils';
import VideoPlayerUtils from '@@utils/VideoPlayerUtils';
import { CHROMECAST_RCV_APP_ID } from '@@utils/constants';
import Logger from '@@utils/logger/Logger';

import { getVideoHeaderBidding } from '../../apis/AdnxsApi';
import i18n from '../../i18n';
import { getStream } from '../../services/VideoStreamService';
import PlayerKeyboardControl from './BitmovinKeyboardControls';
import { buildSbsOnDemandUI } from './BitmovinPlayerUi/UiFactory';
import SubtitleSwitchHandler from './BitmovinPlayerUi/components/SubtitleUtils';
import * as ConvivaMetadata from './ConvivaContentMetadata';
import GoogleDai from './GoogleDai';
import PlayerEvents, { AdPlayingEvent } from './PlayerEvents';
import VideoOztamTracking from './VideoOztamTracking';

declare global {
  interface Window {
    bitmovin: any;
    cast: typeof cast;
    Conviva: any;
    // eslint-disable-next-line camelcase
    tvid_cookie_id: string;
    odabd: boolean;
    player: PlayerAPI;
  }
}

interface ErrorOptions {
  title: string;
  body: string | string[];
}

export interface ChapterData {
  current: number;
  total: number;
}

interface PlayerSession {
  videoId: string;
  timestamp: number;
}

interface BitmovinPlayerProps {
  licenseKey: string;
  id: string;
  containerId: string;
  videoElementId: string;
  adCtaElementId: string;
  videoId: string;
  onControlsToggle: () => void;
  playerEventsOptions: PlayerEventsOptions;
  playerMetadata: {
    name: string;
    sdk: string;
    placement: string;
  }
  plugins: {
    conviva?: {
      useTouchstone: boolean;
      environment: string;
    };
    oztam?: {
      userId: string;
      environment: string;
      vendorVersion: string;
    };
    autoplayNextEpisode?: {
      videoItem: any;
    };
  };
  debugMode?: boolean;
  recordProgress?: (videoId: string, position: number, duration: number) => void;
  withAdSnapback?: boolean;
  errorHandler: (options?: ErrorOptions) => void;
  castMetadata?: any;
}

export interface PlayerUserPrefs {
  volume: number;
  muted: boolean;
  stLang: string;
}

interface TimelineMarker {
  time: number;
}

interface BitmovinClientState {
  initialized: boolean;
  unloading: boolean;
  isSnapback: boolean;
  snapForwardTime: number;
  isSeekingWithinChapter: boolean;
  isSkipAdControlsUsed: boolean;
  convivaContentMetadata: ConvivaMetadata.ContentMetadata;
  playerUserPrefs: PlayerUserPrefs;
  didForcedFocus: boolean;
  currentFocusElement: HTMLDivElement;
  lastFocusElement: HTMLDivElement;
  isAdHoliday: boolean;
  recordPlayerSessionInterval: any;
  hasError: boolean;
  isLivestream: boolean;
  areControlsShown: boolean;
  adCountdownReferenceStartTime: number,
  castStartTime: number,
  // Using this state instead of player.isCasting() because we need to delay setting it back to false
  // See below in onCastFinished()
  isCasting: boolean;

  originalTimelineMarkers: TimelineMarker[];
  originalGetDurationFn: () => number;
  videoStreamMetadata: VideoStream.DaiStream;
  videoHeaderBidding: any;
  timelineMarkers: TimelineMarker[];
  streamUrl: string;
  fallbackStreamUrl: string;
  isStreamDai: boolean;
}

export type VideoCardPosition = 'top' | 'bottom' | 'above-control-bar';

const initialState: BitmovinClientState = {
  initialized: false,
  unloading: false,
  isSnapback: false,
  snapForwardTime: 0,
  isSeekingWithinChapter: false,
  isSkipAdControlsUsed: false,
  convivaContentMetadata: null,
  playerUserPrefs: {
    volume: 75,
    muted: false,
    stLang: '',
  },
  didForcedFocus: false,
  currentFocusElement: null,
  lastFocusElement: null,
  isAdHoliday: null,
  recordPlayerSessionInterval: null,
  hasError: false,
  isLivestream: false,
  areControlsShown: false,
  adCountdownReferenceStartTime: undefined,
  castStartTime: 0,
  isCasting: false,
  originalTimelineMarkers: [],
  originalGetDurationFn: undefined,
  videoStreamMetadata: undefined,
  videoHeaderBidding: undefined,
  timelineMarkers: [],
  streamUrl: '',
  fallbackStreamUrl: '',
  isStreamDai: false,
};

/**
 * Our implementation of Bitmovin player SDK
 */
class BitmovinClient {
  public playerEvents: PlayerEvents;
  public position: number = 0;
  public googleDai: GoogleDai;
  public player: PlayerAPI;
  public castPlayer: any;
  public castPlayerController: any;
  public videoId: string;
  public uiManager: UIManager;
  public adHolidayDuration: number = 60;
  public playerId: string;

  public state: BitmovinClientState = { ...initialState };

  private options: BitmovinPlayerProps;
  private conviva: any;
  private oztam: VideoOztamTracking;
  private playerKeyboardControl: any = null;
  private preUnloadTasks: (() => void)[] = [];
  private resumePosition: number = 0;
  private recordPlayerSessionFrequency = 10000;

  private controlsToggleCallback: (arg: boolean) => void = null;
  private recordProgressHandler: (videoId: string, position: number, duration: number) => void;
  private adSnapBackEnabled: boolean = true;
  private container: HTMLElement;
  private adCtaElement: HTMLElement;
  private adMessageElement: HTMLElement;
  public videoElement: HTMLVideoElement;
  private videoCardsTopContainer: HTMLElement;
  private videoCardsBottomContainer: HTMLElement;
  private videoCardsAboveControlBarContainer: HTMLElement;
  private adClickElement: HTMLElement;
  private seekBarElements: any;
  private controlBarTopElements: any;
  private playbackToggleElement: HTMLElement;

  private plugins: any[] = [];

  private readyProcessed: boolean = false;
  private debugMode: boolean = false;
  private errorHandlerCallback: (options?: ErrorOptions) => void = null;
  private adHolidayTimeoutHandler: ReturnType<typeof setTimeout> = undefined;

  /**
   * Class init
   * @param options
   */
  public init(options: BitmovinPlayerProps) {
    if (typeof window !== 'undefined') {
      this.options = options;

      this.controlsToggleCallback = options.onControlsToggle;
      this.playerId = options.id;
      this.videoId = get(options, 'videoId', null);
      this.debugMode = get(options, 'debugMode', false);
      this.recordProgressHandler = get(options, 'recordProgress', null);
      this.adSnapBackEnabled = get(options, 'withAdSnapback', true);
      this.state.unloading = false;
      this.errorHandlerCallback = get(options, 'errorHandler', null);
      this.state.castStartTime = this.resumePosition;

      this.state.initialized = true;
    }
  }

  public async unloadVideo() {
    this.resumePosition = 0;

    if (this.uiManager) {
      this.uiManager.release();
      delete this.uiManager;
    }

    if (this.player) {
      await this.player.unload();
    }

    if (this.googleDai) {
      this.googleDai.unload();
    }
  }

  public setDomElements = (options) => {
    this.options = {
      ...this.options,
      ...options,
    };

    this.container = document.getElementById(options.containerId) as HTMLElement;
    this.videoElement = document.getElementById(options.videoElementId) as HTMLVideoElement;
    this.adCtaElement = document.getElementById(options.adCtaElementId) as HTMLElement;
    this.adMessageElement = document.querySelector(`#${options.adCtaElementId} .admessage`) as HTMLElement;
    this.adClickElement = document.querySelector(`#${options.adCtaElementId} .adclick`);
  };

  public fetchVideoMetadata = async (videoId, demuxed: boolean = true) => {
    if (!videoId) {
      throw new Error('Missing video ID');
    }

    this.videoId = videoId;

    let demuxedEnabled = demuxed;
    if (process.env.BVAR_PLAYBACK_DEMUXED) {
      demuxedEnabled = process.env.BVAR_PLAYBACK_DEMUXED === 'true';
    }
    if (ls.get('demuxed') !== null) {
      demuxedEnabled = ls.get('demuxed');
    }

    // @todo: review this. Fetch Video metadata function should not cause a side effect like clearing state
    // clear the old interval to prevent having more than one record player session
    // when we play another video without exiting the player
    if (this.state.recordPlayerSessionInterval) {
      clearInterval(this.state.recordPlayerSessionInterval);
    }
    this.state = { ...initialState };

    this.clearTimelineMarkers();

    try {
      if (this.container) {
        this.container.classList.remove('source-loaded');
      }

      const params: Record<string, string> = {};
      if (demuxedEnabled) {
        params.audio = 'demuxed';
      }

      const stream = await getStream(this.videoId, params);
      if (!stream.videoId) {
        throw new Error('Failed fetching video stream config');
      }

      this.state.videoStreamMetadata = stream;
      this.state.isLivestream = get(stream, 'videoStreamType', 'vod') === 'live';

      Logger.info(`Video stream loaded for ${this.videoId}`);

      try {
        this.state.videoHeaderBidding = await this.fetchVideoHeaderBidding();
      } catch (err) {
        Logger.warn(`Video header bidding error: ${err.message}`);
      }

      return stream;
    } catch (error) {
      if (error.message === 'expired') {
        throw new Error('Video Expired');
      } else if (error.message === 'You must be signed in') {
        throw new Error('Unauthorized');
      } else if (error.message === 'not found' || error.message === 'Request failed with status code 404') {
        throw new Error('Video Not Found');
      } else {
        throw new Error(`Error: ${error.message}`);
      }
    }
  };

  public fetchVideoHeaderBidding = () => {
    const { bidding, adTag } = this.state.videoStreamMetadata;

    if (bidding && adTag) {
      // Customising the payload
      bidding.site.page = adTag.description_url;

      bidding.device.ip = adTag.ipaddress;
      bidding.device.ua = window.navigator.userAgent;
      bidding.device.devicetype = isDesktop ? 2 : 1;
      bidding.device.ifa = '';

      bidding.user.gdpr.consentrequired = false;
      bidding.user.gdpr.consentstring = '';

      bidding.video.w = 1280;
      bidding.video.h = 720;
      bidding.video.mimes = ['video/mp4'];
      bidding.video.protocols = [2, 3, 4];

      bidding.includebrandcategory = {
        primaryadserver: 1,
      };

      bidding.pricegranularity = {
        precision: 4,
        ranges: [{ max: 20, increment: 0.01 }],
      };

      // John Lay has discovered that the properties in the JSON object need to be in a certain order...
      // @TODO simplify when/if Xandr can do better and not require specific order
      return getVideoHeaderBidding({
        site: { ...bidding.site },
        content: { ...bidding.content },
        device: { ...bidding.device },
        podconfig: { ...bidding.podconfig },
        user: { ...bidding.user },
        video: { ...bidding.video },
        xdyn_params: { ...bidding.xdyn_params },
      });
    }

    return new Promise((resolve) => {
      resolve(null);
    });
  };

  private updateConvivaMetadata = () => {
    const convivaOptions = get(this.options.plugins, 'conviva', null);
    if (!convivaOptions) {
      return;
    }

    const { convivaAssetMetadata } = this.state.videoStreamMetadata;

    const assetMetadata = {
      ...convivaAssetMetadata,
      streamProtocol: 'https',
      connectionType: 'N/A',
      playerVendor: 'Bitmovin',
      playerVersion: Player.version,
    };

    const rootFields = [
      'assetName',
      'streamType',
      'viewerId',
      'applicationName',
      'streamUrl',
    ];

    const customMetadata: ConvivaMetadata.CustomContentMetadata = Object.keys(assetMetadata)
      .filter((key) => { return !rootFields.includes(key) && assetMetadata[key] !== null; })
      .reduce((obj, key) => {
        const result = { ...obj };
        if (assetMetadata[key]) {
          result[key] = assetMetadata[key].toString();
        }
        return result;
      }, {});

    this.state.convivaContentMetadata = {
      assetName: assetMetadata.assetName,
      streamType: assetMetadata.streamType,
      viewerId: assetMetadata.viewerId,
      duration: assetMetadata.streamDuration,
      applicationName: this.options.playerMetadata.name,
      streamUrl: '', // Will be set by this.loadSource()
      custom: customMetadata,
    };

    this.conviva.updateContentMetadata(this.state.convivaContentMetadata);
  };

  public launch = (resumePosition = 0) => {
    this.resumePosition = resumePosition;
    this.container.classList.remove('source-loaded');

    if (!this.player) {
      /* Instantiating Bitmovin player object */
      const playerConfig = VideoPlayerUtils.generateBitmovinPlayerConfig(
        this.options.licenseKey,
        this.state.videoStreamMetadata,
      );

      this.player = new Player(this.container, this.preparePlayerConfigForChromecast(playerConfig));
      if (this.debugMode) {
        window.player = this.player;
      }
    }

    this.loadPlayerUi();
    this.initEarlyPlugins();

    const {
      streamProviderType, fallbackContentUrl, adTag, providerAccountId,
    } = this.state.videoStreamMetadata;

    this.googleDai = new GoogleDai(this);

    /**
     * Instantiating Google DAI helper class
     */
    if (streamProviderType === 'GoogleDAIProvider') {
      this.state.isStreamDai = true;

      if (fallbackContentUrl !== '') {
        this.state.fallbackStreamUrl = fallbackContentUrl;
      }

      if (this.state.isLivestream) {
        this.googleDai.loadLivestreamByAssetKey(this.state.videoStreamMetadata.videoId, adTag);
      } else {
        this.googleDai.loadVodByVideoId(this.state.videoStreamMetadata.videoId, providerAccountId, adTag);
      }
    }

    if (!this.playerKeyboardControl) {
      // eslint-disable-next-line no-new
      this.playerKeyboardControl = new PlayerKeyboardControl(this.player, this.uiManager);
    } else {
      this.playerKeyboardControl.enable();
    }

    // It is important that any logic that relies on events to be executed after loading of video source.
    // This is because Google DAI events are only instantiated inside googleDai.loadVodByVideoId()

    const playerEventOptions: PlayerEventsOptions = {
      ...this.options.playerEventsOptions,
      duration: this.state.videoStreamMetadata.video.duration,
      resumePosition: this.resumePosition,
      debugMode: this.debugMode,
    };

    if (!this.playerEvents) {
      this.playerEvents = new PlayerEvents(this, playerEventOptions);
      this.initEvents();
    } else {
      this.playerEvents.reset(this, playerEventOptions);
    }

    this.initLatePlugins();

    if (this.state.isStreamDai === false) {
      Logger.info('BitmovinClient: Not a DAI stream');
      this.loadSource(this.state.videoStreamMetadata.contentUrl, true);
    }

    const localUserPrefs: PlayerUserPrefs = ls.get('od.player.userPrefs');
    if (localUserPrefs) {
      this.state.playerUserPrefs = {
        ...this.state.playerUserPrefs,
        ...localUserPrefs,
      };
    }
  };

  public getVideoMetadata = () => {
    if (this.state.videoStreamMetadata) {
      return this.state.videoStreamMetadata.video;
    }

    return null;
  };

  public getVideoStreamMetadata = () => {
    if (this.state.videoStreamMetadata) {
      return this.state.videoStreamMetadata;
    }

    return null;
  };

  private startAdHoliday = () => {
    if (this.state.isLivestream) {
      Logger.info('BitmovinClient: No Ad Holiday for livestreams');
    } else if (this.state.isAdHoliday === null) {
      Logger.info(`BitmovinClient: Ad holiday started for ${this.adHolidayDuration} seconds`);
      this.state.isAdHoliday = true;

      this.adHolidayTimeoutHandler = setTimeout(() => {
        Logger.info('BitmovinClient: Ad holiday ended');
        this.state.isAdHoliday = false;
      }, this.adHolidayDuration * 1000);
    } else {
      Logger.info('BitmovinClient: Ad holiday period already occurred in this session');
    }
  };

  private onControlsShow = () => {
    this.state.areControlsShown = true;
    // Since the settings panel has a different auto-hide timeout than the main controls
    // If the main controls were auto-hidden before the settings panel, upon showing the controls again
    // re-focus on the panel again.
    if (this.state.currentFocusElement) {
      this.state.didForcedFocus = true;
      this.state.currentFocusElement.focus();
    }

    const bmControlBarElements = document.getElementsByClassName('bmpui-ui-controlbar');
    for (let ei = 0; ei < bmControlBarElements.length; ei += 1) {
      const element = bmControlBarElements[ei];
      element.setAttribute('aria-hidden', 'false');
    }

    const bmTitleBarElements = document.getElementsByClassName('bmpui-ui-titlebar');
    for (let ei = 0; ei < bmTitleBarElements.length; ei += 1) {
      const element = bmTitleBarElements[ei];
      element.setAttribute('aria-hidden', 'false');
    }

    if (this.controlsToggleCallback) {
      this.controlsToggleCallback(true);
    }

    this.adjustPlayerMenusPositions();
  };

  private onControlsHide = () => {
    this.state.areControlsShown = false;
    const bmControlBarElements = document.getElementsByClassName('bmpui-ui-controlbar');
    for (let ei = 0; ei < bmControlBarElements.length; ei += 1) {
      const element = bmControlBarElements[ei];
      element.setAttribute('aria-hidden', 'true');
    }

    const bmTitleBarElements = document.getElementsByClassName('bmpui-ui-titlebar');
    for (let ei = 0; ei < bmTitleBarElements.length; ei += 1) {
      const element = bmTitleBarElements[ei];
      element.setAttribute('aria-hidden', 'true');
    }

    if (this.controlsToggleCallback) {
      this.controlsToggleCallback(false);
    }
  };

  private loadPlayerUi = () => {
    if (!this.uiManager) {
      try {
        this.uiManager = buildSbsOnDemandUI(this, {
          playbackSpeedSelectionEnabled: false,
          seekbarSnappingRange: 0,
          enableSeekPreview: false,
          videoStreamMetadata: this.state.videoStreamMetadata,
          playerMetadata: this.options.playerMetadata,
          disableAutoHideWhenHovered: !isMobile,
        });
      } catch (err) {
        Logger.error('error building custom Bitmovin UI', {
          error: {
            stack: err.stack,
            message: err.message,
          },
        });
      }

      if (this.uiManager) {
        const currentUi = get(this, 'uiManager.currentUi');
        currentUi.onControlsShow.subscribe(this.onControlsShow);
        currentUi.onControlsHide.subscribe(this.onControlsHide);

        // if the user genuinely navigated to another player control element then we need to reset
        // the current (settings panel item) and last focus (settings icon) elements so that they
        // will not be automatically refocused by the functions above.
        this.container.addEventListener('focus', () => {
          if (this.state.didForcedFocus === false) {
            if (this.state.currentFocusElement) {
              this.state.currentFocusElement = null;
            }

            if (this.state.lastFocusElement) {
              this.state.lastFocusElement = null;
            }
          } else {
            this.state.didForcedFocus = false;
          }
        }, true);
      }
    }
  };

  public hideUi = () => {
    const currentUi = get(this, 'uiManager.currentUi');
    currentUi.getUI().hide();
  };

  public addVideoCardV2 = (card: HTMLElement, position: VideoCardPosition = 'top'): void => {
    if (position === 'top') {
      this.videoCardsTopContainer.appendChild(card);
    } else if (position === 'bottom') {
      this.videoCardsBottomContainer.appendChild(card);
    } else if (position === 'above-control-bar') {
      this.videoCardsAboveControlBarContainer.appendChild(card);
    }
  };

  public resetEarlyPlugins = () => {
    if (this.oztam) {
      this.oztam.unload();
    }

    if (this.conviva) {
      this.conviva.release();
    }
  };

  private initEarlyPlugins = () => {
    const plugins = get(this.options, 'plugins', null);
    const convivaOptions = get(plugins, 'conviva', null);

    if (convivaOptions) {
      const convivaConfig = VideoPlayerUtils.generateConvivaPluginsOptions(
        convivaOptions.useTouchstone,
        convivaOptions.environment,
      );

      this.conviva = new ConvivaAnalytics(
        this.player,
        convivaConfig.customerKey,
        convivaConfig.pluginConfig,
      );

      this.updateConvivaMetadata();
      this.conviva.initializeSession();
    }

    const oztamOptions = get(plugins, 'oztam', null);

    if (oztamOptions) {
      if (!this.oztam) {
        this.oztam = new VideoOztamTracking();
      }

      this.oztam.init(
        this,
        {
          ...this.state.videoStreamMetadata.oztamMetadata,
          videoStreamType: this.state.videoStreamMetadata.videoStreamType,
          streamMetadata: this.state.videoStreamMetadata,
          ...oztamOptions,
        },
      );

      this.plugins.push(this.oztam);
    }
  };

  private initLatePlugins = () => {
    this.plugins.forEach((plugin) => {
      if (plugin instanceof VideoOztamTracking && !this.oztam.eventInitialized) {
        plugin.initPlayerEvents();
      }
    });
  };

  public updateAdMessage = (remainingTime: number) => {
    if (
      this.adMessageElement
      && remainingTime > 0
    ) {
      (this.adMessageElement as HTMLElement).innerHTML = i18n.t(
        'common:videoPlayer.adTimeRemaining',
        {
          count: Math.ceil(remainingTime),
          formatParams: {
            count: {
              round: false,
              delimiter: ' ',
              spacer: remainingTime <= 60 ? ' ' : '',
              units: ['h', 'm', 's'],
              shortUnits: remainingTime > 60,
            },
          },
        },
      );
    } else {
      (this.adMessageElement as HTMLElement).innerHTML = '';
    }
  };

  public toggleAdControls = (enabled = false) => {
    // There is no ad CTA element in the DOM
    if (!this.adCtaElement) {
      return;
    }

    // Ad controls are already displayed
    if (enabled === true && (
      this.container.classList.contains('ad-controls')
      || this.container.classList.contains('skip-ad-controls')
    )) {
      return;
    }

    let streamTime = this.playerEvents.state.seekTargetTime;
    if (streamTime === 0) {
      streamTime = this.getStreamTime();
    }

    let useSkipAdControl = false;
    if (
      this.getIsAdHoliday()
      || (
        this.state.isSnapback === false
        && this.state.snapForwardTime === 0
        && streamTime < this.resumePosition
      )
    ) {
      useSkipAdControl = true;
    }

    this.state.isSkipAdControlsUsed = useSkipAdControl;

    if (!enabled || useSkipAdControl) {
      this.updateAdMessage(0);
    }

    this.playerKeyboardControl.setAllowSeeking(!enabled);
    const forwardButton = get(document.getElementsByClassName('bmpui-ui-fastforwardbutton'), '[0]');
    const backButton = get(document.getElementsByClassName('bmpui-ui-rewindbutton'), '[0]');
    if (forwardButton) {
      forwardButton.disabled = enabled && !useSkipAdControl;
      backButton.disabled = enabled && !useSkipAdControl;
    }

    // When not DAI, Bitmovin by default hides the control bar
    // We want to display it so that users can unmute in-case of autoplay muted
    if (!this.state.isStreamDai) {
      document.querySelector('.bmpui-ui-uicontainer').classList.add('force-display');
    }

    if (this.seekBarElements) {
      // When ad controls are enabled, we are actually hiding the regular playback controls
      if (enabled === true) {
        this.container.classList.add(useSkipAdControl ? 'skip-ad-controls' : 'ad-controls');
      } else {
        this.container.classList.remove('ad-controls');
        this.container.classList.remove('skip-ad-controls');
      }
    }

    if (enabled === true) {
      this.adCtaElement.style.display = 'flex';
    } else {
      this.adCtaElement.style.display = 'none';
    }
  };

  public getContainerElement = () => {
    return this.container;
  };

  public getVideoElement = () => {
    return this.videoElement;
  };

  public getAdClickElement = () => {
    return this.adClickElement;
  };

  public getIsAdHoliday = () => {
    return this.state.isAdHoliday;
  };

  public getOztamSessionId = () => {
    if (this.oztam) {
      return this.oztam.getSessionId();
    }
    return null;
  };

  private registerPlayerControlElements = () => {
    if (!this.seekBarElements) {
      this.seekBarElements = document.querySelectorAll('.bmpui-ui-seekbar');
    }

    if (!this.controlBarTopElements) {
      this.controlBarTopElements = document.querySelectorAll('.bmpui-controlbar-top');
    }

    if (!this.playbackToggleElement) {
      this.playbackToggleElement = (document.querySelector('.bmpui-ui-playbacktoggle-overlay') as HTMLElement);
    }
  };

  /**
   * Listening to the player events
   */
  private initEvents = () => {
    this.playerEvents.on('CuePointsChanged', this.onCuePointsChanged);
    this.playerEvents.on('StreamError', this.onStreamError);
    this.playerEvents.on('Warning', this.onWarning);
    this.playerEvents.on('Error', this.onError);
    this.playerEvents.on('VideoTagError', this.onVideoTagError);
    this.playerEvents.on('AdStarted', this.onAdStarted);
    this.playerEvents.on<AdPlayingEvent>('AdPlaying', this.onAdPlaying);
    this.playerEvents.on('AdFinished', this.onAdFinished);
    this.playerEvents.on('AdBreakStarted', this.onAdBreakStarted);
    this.playerEvents.on('AdBreakFinished', this.onAdBreakFinished);
    this.playerEvents.on('SeekStarted', this.onSeekStarted);
    this.playerEvents.on('SeekFinished', this.onSeekFinished);

    this.playerEvents.on('Paused', this.onPaused);
    this.playerEvents.on('Ready', this.onPlayerReady);
    this.playerEvents.on('PlayerResized', this.adjustPlayerMenusPositions);
    this.playerEvents.on('SourceLoaded', this.onSourceLoaded);
    this.playerEvents.on('ContentStarted', this.onContentStarted);
    this.playerEvents.on('Progress', this.recordProgress);
    this.playerEvents.on('PlayFinished', this.onPlayFinished);
    this.playerEvents.on('PlayStarted', this.onPlayStarted);
    this.playerEvents.on('VolumeChanged', this.onVolumeChanged);
    this.playerEvents.on('Muted', this.onMuted);
    this.playerEvents.on('Unmuted', this.onUmuted);
    this.playerEvents.on('SubtitleEnabled', this.onSubtitleEnabled);
    this.playerEvents.on('SubtitleDisabled', this.onSubtitleDisabled);
    this.playerEvents.on('ViewModeChanged', this.onViewModeChanged);
    this.playerEvents.on('CastAvailable', this.onCastAvailable);
    this.playerEvents.on('CastInit', this.onCastInit);
    this.playerEvents.on('CastPending', this.onCastPending);
    this.playerEvents.on('CastStarted', this.onCastStarted);
    this.playerEvents.on('CastFinished', this.onCastFinished);
    this.playerEvents.on('BufferingStarted', this.onBufferingStarted);
    this.playerEvents.on('BufferingFinished', this.onBufferingFinished);

    this.adClickElement.addEventListener('click', this.onAdClick);
  };

  private recordPlayerSession = () => {
    Logger.debug('Recording data for player session');
    ls.set('od.player.session', { videoId: this.videoId, timestamp: new Date().getTime() });
  };

  private recordProgress = () => {
    if (
      this.state.isCasting === false // Progress recording will happen on the cast device
      && this.state.hasError === false
      && typeof this.recordProgressHandler === 'function'
    ) {
      const currentContentPosition = this.getCurrentContentPosition();
      if (currentContentPosition) {
        this.recordProgressHandler(this.videoId, currentContentPosition, this.state.videoStreamMetadata.video.duration);
      }
    }
  };

  private sendCustomConvivaEvent = (eventName, eventAttributes) => {
    if (this.conviva) {
      this.conviva.sendCustomApplicationEvent(eventName, eventAttributes);
    }
  };

  /**
   * Can be used to send custom messages to Conviva upon playback issues not being captured by the normal operation of
   * the Conviva plugin
   * @param message
   * @param level
   */
  private sendCustomConvivaLog = (message, level: 'fatal' | 'warning' = 'fatal') => {
    if (this.conviva) {
      const { Conviva } = window;

      let logLevel;
      let endSession = true;
      switch (level) {
        case 'warning':
          logLevel = Conviva.Client.ErrorSeverity.WARNING;
          endSession = false;
          break;

        case 'fatal':
        default:
          logLevel = Conviva.Client.ErrorSeverity.FATAL;
          break;
      }

      this.conviva.reportPlaybackDeficiency(message, logLevel, endSession);
    } else {
      Logger.warn('Conviva was not available to send custom log', {
        videoId: this.videoId,
        isDai: this.state.isStreamDai,
        message,
      });
    }
  };

  private onCuePointsChanged = (event) => {
    const streamData = event.getStreamData();
    const cuepoints = get(streamData, 'cuepoints', []);

    this.state.timelineMarkers = [];
    /** Generating chapter break markers for the scrub bar */
    for (let ci = 0; ci < cuepoints.length; ci += 1) {
      const cuepoint = cuepoints[ci];

      if (ci === 0 && cuepoint.start !== 0) {
        this.state.timelineMarkers.push({
          time: 0,
        });
      }

      this.state.timelineMarkers.push({
        time: cuepoint.start,
      });
    }
  };

  public getStreamTime = () => {
    if (this.player) {
      return this.player.getCurrentTime();
    }

    Logger.error('BitmovinClient: error fetching current time, player inexistant', {
      error: {
        videoId: this.videoId,
        isDai: this.state.isStreamDai,
      },
    });

    return null;
  };

  public getCurrentContentPosition = () => {
    const streamTime = this.getStreamTime();

    if (streamTime === null) {
      return null;
    }

    return this.contentTimeForStreamTime(streamTime);
  };

  private onContentStarted = () => {
    this.toggleAdControls(false);
    this.preUnloadTasks.push(() => {
      // Only record progress during unload if the player was not playing.
      // Prevent double recording when closing while paused or video already completed
      if (
        this.playerEvents.state.isPlaying
        && !this.playerEvents.state.isContentCompleted
      ) {
        this.recordProgress();
      }
    });

    // No need for this as we don't support Ad Holidays for livestreams
    if (!this.state.isLivestream) {
      this.recordPlayerSession();
      this.state.recordPlayerSessionInterval = setInterval(() => {
        this.recordPlayerSession();
      }, this.recordPlayerSessionFrequency);
    }
  };

  private onPlayStarted = () => {
    // If we are using skip-ad-controls, this means we are using custom logic for ads after resume
    // We can't rely on DAI ad start/end events. This can be simplified by using a SKIP AD button
    // which, onClick, would toggle the ad controls off and jump to the content
    if (this.state.isSkipAdControlsUsed === true) {
      const streamTime = this.player.getCurrentTime();
      const isAd = this.streamTimeIsAd(streamTime);

      if (isAd === false) {
        this.toggleAdControls(false);
      }
    }

    this.container.classList.remove('cast-pending');
    this.container.classList.remove('buffering');

    this.state.isSnapback = false;
  };

  private onPlayFinished = () => {
    this.controlsToggleCallback(true);
    this.recordProgress();
  };

  private onVolumeChanged = (event) => {
    if (event.issuer === 'ui-volumecontroller') {
      const { targetVolume } = event;
      this.state.playerUserPrefs.volume = targetVolume;

      // When changing volume, Bitmovin also unmutes the video
      this.state.playerUserPrefs.muted = false;
      ls.set('od.player.userPrefs', this.state.playerUserPrefs);
    }
  };

  private onMuted = (event) => {
    if (event.issuer === 'ui-volumecontroller') {
      this.state.playerUserPrefs.muted = true;
      ls.set('od.player.userPrefs', this.state.playerUserPrefs);
    }
  };

  private onUmuted = () => {
    this.state.playerUserPrefs.muted = false;
    ls.set('od.player.userPrefs', this.state.playerUserPrefs);
  };

  private onSubtitleEnabled = (event) => {
    const { subtitle } = event;
    if (!SubtitleSwitchHandler.isSubtitleForced(subtitle)) {
      this.state.playerUserPrefs.stLang = subtitle.lang;
      ls.set('od.player.userPrefs', this.state.playerUserPrefs);
    }
  };

  private onSubtitleDisabled = () => {
    this.state.playerUserPrefs.stLang = '';
    ls.set('od.player.userPrefs', this.state.playerUserPrefs);
  };

  private onViewModeChanged = (event) => {
    const { to } = event;

    if (to === 'fullscreen') {
      this.getContainerElement().classList.add('fullscreen');
    } else {
      this.getContainerElement().classList.remove('fullscreen');
    }
  };

  // Cleanly stop current cast playback before requesting a new content
  private stopExistingCastSession = () => {
    if ('cast' in window && window.cast) {
      this.castPlayer = new window.cast.framework.RemotePlayer();
      this.castPlayerController = new window.cast.framework.RemotePlayerController(this.castPlayer);

      const context = window.cast.framework.CastContext.getInstance();
      const castState = context.getCastState();

      const stopPreviousSession = () => {
        const session = context.getCurrentSession();
        const mediaSession = session.getMediaSession();

        if (mediaSession && 'media' in mediaSession && mediaSession.media.contentId !== this.videoId) {
          this.castPlayerController.stop();
        }
      };

      if (castState !== 'CONNECTED') {
        const handlesIsConnectedChanged = (e) => {
          if (e.value === true) {
            stopPreviousSession();
          }

          this.castPlayerController.removeEventListener(window.cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED, handlesIsConnectedChanged);
        };

        this.castPlayerController.addEventListener(window.cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED, handlesIsConnectedChanged);
      } else {
        stopPreviousSession();
      }
    }
  };

  private onCastAvailable = () => {
    this.stopExistingCastSession();
  };

  private onCastInit = () => {
    if (!this.resumePosition) {
      // Have to do it this way because the player current position is 0 when calling from inside prepareMediaInfo()
      this.state.castStartTime = this.getCurrentContentPosition();
    }
  };

  private onCastPending = () => {
    this.container.classList.add('cast-pending');
    this.container.classList.add('buffering');
  };

  private onCastStarted = () => {
    this.castPlayerController.addEventListener(window.cast.framework.RemotePlayerEventType.MEDIA_INFO_CHANGED, this.onCastMediaInfoChanged);
    this.castPlayerController.addEventListener(window.cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED, this.onCastPlayerStateChanged);
    // @ts-ignore unresolved variable IS_PLAYING_BREAK_CHANGED
    this.castPlayerController.addEventListener(window.cast.framework.RemotePlayerEventType.IS_PLAYING_BREAK_CHANGED, this.onCastIsPlayingBreakChanged);
    this.container.classList.add('casting');

    this.state.isCasting = true;
    this.toggleAdControls(false);
    this.container.classList.remove('buffering');
  };

  private onCastFinished = () => {
    Logger.info('BitmovinClient/Chromecast: Casting ended, resetting duration & the timeline markers');
    if (this.state.originalGetDurationFn) {
      this.player.getDuration = this.state.originalGetDurationFn;
    }

    if (this.state.originalTimelineMarkers.length > 0) {
      this.showTimelineMarkers(this.state.originalTimelineMarkers);
      this.state.originalTimelineMarkers = [];
    }

    this.container.classList.remove('casting');
    const bitmovinPlayerPoster = document.getElementsByClassName('bitmovinplayer-poster');
    if (bitmovinPlayerPoster) {
      (bitmovinPlayerPoster[0] as HTMLElement).style.removeProperty('backgroundImage');
      (bitmovinPlayerPoster[0] as HTMLElement).style.removeProperty('display');
      (bitmovinPlayerPoster[0] as HTMLElement).style.removeProperty('backgroundSize');
    }

    // When casting is finished, the sender player will update and might perform some actions which should be ignored.
    // An example would be seeking to the correct content position at time of cast finished that might trigger a snapback.
    setTimeout(() => {
      this.state.isCasting = false;
    }, 5000);
  };

  private onCastPlayerStateChanged = (event) => {
    if (event.value === 'PLAYING') {
      this.state.isCasting = true;
    }
  };

  private onCastIsPlayingBreakChanged = (event) => {
    const { value: isAd } = event;
    this.toggleAdControls(isAd);
  };

  // Chromecast event handler, called when the Media Info has changed on the receiver app
  private onCastMediaInfoChanged = (event) => {
    const duration = get(event, 'value.duration');
    const metadata = get(event, 'value.metadata', {});
    const breaks = get(event, 'value.breaks');

    if (
      this.state.isLivestream
      || !this.state.isStreamDai
    ) {
      return;
    }

    if (duration > 0) {
      Logger.info('BitmovinClient/Chromecast: media has changed, overriding media duration', event);
      if (this.state.originalGetDurationFn === undefined) {
        this.state.originalGetDurationFn = this.player.getDuration;
      }

      this.player.getDuration = () => {
        return duration;
      };
    }

    const backgroundImage = get(metadata, 'images[0]');

    if (backgroundImage) {
      const castInfoElement = document.getElementById(`castInfo-${this.playerId}`);
      (castInfoElement.firstChild as HTMLElement).innerHTML = metadata.title;
      (castInfoElement.lastChild as HTMLElement).innerHTML = metadata.subtitle;

      const bitmovinPlayerPoster = document.getElementsByClassName('bitmovinplayer-poster');
      if (bitmovinPlayerPoster) {
        (bitmovinPlayerPoster[0] as HTMLElement).style.backgroundImage = `url(${backgroundImage.url})`;
        (bitmovinPlayerPoster[0] as HTMLElement).style.display = 'block';
        (bitmovinPlayerPoster[0] as HTMLElement).style.backgroundSize = 'cover';
      }
    }

    if (breaks) {
      if (this.state.originalTimelineMarkers.length === 0) {
        this.state.originalTimelineMarkers = [...this.state.timelineMarkers];
      }

      const newMarkers = [];
      for (let ci = 0; ci < breaks.length; ci += 1) {
        const cuepoint = breaks[ci];

        if (ci === 0 && cuepoint.position !== 0) {
          newMarkers.push({
            time: 0,
          });
        }

        newMarkers.push({
          time: cuepoint.position,
        });
      }

      if (JSON.stringify(newMarkers) !== JSON.stringify(this.state.timelineMarkers)) {
        Logger.info('BitmovinClient/Chromecast: media has changed, updating timeline markers', event);
        this.showTimelineMarkers(newMarkers);
      }
    }
  };

  private onBufferingStarted = () => {
    this.container.classList.add('buffering');
  };

  private onBufferingFinished = () => {
    this.container.classList.remove('buffering');
  };

  /**
   * Clears any existing markers and display new ones
   */
  private showTimelineMarkers = (markers?: TimelineMarker[]) => {
    this.clearTimelineMarkers();

    if (markers) {
      this.state.timelineMarkers = [...markers];
    }

    this.state.timelineMarkers.forEach((marker) => {
      if (marker.time > 0) {
        this.uiManager.addTimelineMarker(marker);
      }
    });
  };

  /**
   * Clears any existing markers from the timeline
   */
  private clearTimelineMarkers = () => {
    if (this.uiManager) {
      const currentMarkers = this.uiManager.getTimelineMarkers();

      // traversing a copy of the marker list as we are going to remove them from the original list
      [...currentMarkers].forEach((marker) => {
        this.uiManager.removeTimelineMarker(marker);
      });
    }
  };

  private onPlayerReady = () => {
    if (!this.readyProcessed) {
      this.registerPlayerControlElements();

      if ('cast' in window && !this.castPlayer) {
        this.castPlayer = new window.cast.framework.RemotePlayer();
        this.castPlayerController = new window.cast.framework.RemotePlayerController(this.castPlayer);
      }

      // video-player-cards-top cover the player control
      this.videoCardsTopContainer = document.createElement('div');
      this.videoCardsTopContainer.classList.add('video-player-cards-top');
      const bitmovinContainerWrapper = document.querySelector('.bmpui-container-wrapper');
      bitmovinContainerWrapper.appendChild(this.videoCardsTopContainer);

      // video-player-cards-bottom is behind the player control
      this.videoCardsBottomContainer = document.createElement('div');
      this.videoCardsBottomContainer.classList.add('video-player-cards-bottom');
      const bitmovinControlBar = document.querySelector('.bmpui-ui-controlbar');
      bitmovinControlBar.parentNode.insertBefore(this.videoCardsBottomContainer, bitmovinControlBar);

      // video-player-cards-above-control-bar is positioned just above the control bar
      this.videoCardsAboveControlBarContainer = document.createElement('div');
      this.videoCardsAboveControlBarContainer.classList.add('video-player-cards-above-control-bar');
      bitmovinControlBar.parentNode.insertBefore(this.videoCardsAboveControlBarContainer, bitmovinControlBar);

      const { volume, muted } = this.state.playerUserPrefs;
      this.player.setVolume((volume), 'userPrefs');

      if (muted) {
        this.player.mute('userPrefs');
      } else {
        this.player.unmute('userPrefs');
      }

      this.state.areControlsShown = document.getElementsByClassName('bmpui-controlbar-visible').length > 0;
      if (this.state.areControlsShown === false) {
        this.controlsToggleCallback(false);
      }

      const availableAudioTracks = this.player.getAvailableAudio();
      if (availableAudioTracks?.length > 1) {
        document.getElementsByClassName('bmpui-ui-settings-panel-audio')[0].classList.add('show');
      }

      const availableSubtitles = this.player.subtitles.list();
      if (availableSubtitles?.length > 0) {
        document.getElementsByClassName('bmpui-ui-settings-panel-subtitles')[0].classList.add('show');
      }

      this.adjustPlayerMenusPositions();
    }

    // Display ad controls if starting/resuming into an ad
    const streamTime = this.player.getCurrentTime();
    const isAd = this.streamTimeIsAd(streamTime);
    this.toggleAdControls(isAd);

    this.showTimelineMarkers();
  };

  // Using debounce to give time for the UI to fully render and buttons to be visible before adjusting positions
  private adjustPlayerMenusPositions = debounce(() => {
    let marginAdjustment = 20;
    if (isMobile) {
      marginAdjustment = 200;
    }

    // Dynamically align the video quality menu and its toggle button
    const settingsPanel = get(document.getElementsByClassName('bmpui-ui-settings-panel-videoquality'), '[0]') as HTMLDivElement;
    const settingsButton = get(document.getElementsByClassName('bmpui-ui-settingstogglebutton'), '[0]') as HTMLDivElement;

    // if element is not visible element.offsetParent will return null
    if (settingsPanel && settingsButton && settingsButton.offsetParent) {
      const settingsButtonBoundaries = settingsButton.getBoundingClientRect();
      settingsPanel.style.marginRight = `${(window.innerWidth - settingsButtonBoundaries.right - marginAdjustment).toString()}px`;
    }

    // Dynamically align the subtitle menu and its toggle button
    const subtitleMenuPanel = get(document.getElementsByClassName('bmpui-ui-settings-panel-subtitleaudio'), '[0]') as HTMLDivElement;
    const subtitleButton = get(document.getElementsByClassName('bmpui-ui-subtitlesettingstogglebutton'), '[0]') as HTMLDivElement;
    if (subtitleMenuPanel && subtitleButton && subtitleButton.offsetParent) {
      const subtitleButtonBoundaries = subtitleButton.getBoundingClientRect();
      subtitleMenuPanel.style.marginRight = `${(window.innerWidth - subtitleButtonBoundaries.right - marginAdjustment).toString()}px`;
    }
  }, 500);

  private onSourceLoaded = (event) => {
    this.updateConvivaMetadata();

    if (this.adHolidayTimeoutHandler) {
      clearTimeout(this.adHolidayTimeoutHandler);
    }

    if (this.state.isStreamDai) {
      const streamData = event.getStreamData();
      this.loadSource(streamData.url);
      this.state.streamUrl = streamData.url;
    }

    this.registerPlayerControlElements();

    // With the new UI, we don't display the hugeplaybutton anymore,
    // so we need to display the UI when it is first loaded in case the playback was paused at start by the browser.
    // 1s after the player is Ready, if the video is not playing, then show the control bar.
    setTimeout(() => {
      if (this.uiManager) {
        const currentUi = get(this, 'uiManager.currentUi');
        const uiComponents = currentUi.getUI().getComponents();

        const controlBar = find(uiComponents, (component) => {
          return component instanceof ControlBar;
        });

        if (controlBar) {
          controlBar.show();
        }
      }
    }, 1000);
  };

  private onVideoTagError = async (event) => {
    const errorObject = get(event, 'errorObject');
    const { streamProviderType } = this.state.videoStreamMetadata;

    Logger.error('BitmovinClient: Received an error on <video> tag', {
      error: {
        videoId: this.videoId,
        isDai: this.state.isStreamDai,
        adBlockerDetected: window.odabd,
        ...(errorObject && { code: errorObject.code }),
        contentTime: this.playerEvents.state.lastContentTime,
      },
    });

    if (errorObject?.mediaError === 'MEDIA_ERR_DECODE') {
      const { lastContentTime, isAd, currentAdMetadata: ad } = this.playerEvents.state;

      this.sendCustomConvivaLog('Browser failed decoding media', 'warning');
      this.sendCustomConvivaEvent('SbsStreamErrorRecovery', {
        videoId: this.videoId,
        errorInfo: `id_${this.videoId}|time_${Math.floor(lastContentTime / 10) * 10}|${errorObject.message}|${isAd ? `adid_${ad.id}` : 'content'}${isAd ? `|adpod_${ad.podIndex}|adpos_${ad.position}` : ''}`,
        adInfo: ad ? `id_${ad.id}|cid_${ad.creativeId}|"${ad.name}"` : '',
        providerPlatform: `${streamProviderType}|web`,
      });
      this.toggleAdControls(false);
      this.unloadVideo().then(() => {
        this.container.classList.remove('source-loaded');
        this.launch(this.playerEvents.state.lastContentTime + 3);
      });
    }
  };

  private onError = async (event) => {
    this.state.hasError = true;
    const {
      name, code, type,
    } = event;

    let contentTime = get(event, 'contentTime', undefined);
    if (contentTime === undefined) {
      contentTime = this.playerEvents.state.lastContentTime;
    }

    Logger.error('BitmovinClient: Received an error', {
      error: {
        videoId: this.videoId,
        isDai: this.state.isStreamDai,
        adBlockerDetected: window.odabd,
        name,
        code,
        type,
        contentTime,
      },
    });

    const errorTitle = i18n.t('common:videoPlayer.errorTitle');
    const errorBody = [];
    let sbsLogin;
    let isInAustralia;

    switch (true) {
      case event.code === 1301:
        // Handled in onVideoError
        Logger.info('BitmovinClient: ignoring 1301 error from Bitmovin as it is handled by the video tag error handler');
        return;

      case event.name === 'SOURCE_MANIFEST_INVALID':
        errorBody.push(`${i18n.t('common:videoPlayer.errorLoadingVideo')} ${i18n.t('common:videoPlayer.errorRestart')}`);
        errorBody.push(i18n.t('common:videoPlayer.errorContactUs'));
        break;

      default:
        sbsLogin = await getSbsLoginInstance();
        isInAustralia = await sbsLogin.isInAustralia();

        if (!isInAustralia) {
          errorBody.push(`${i18n.t('common:videoPlayer.errorLoadingVideo')} ${i18n.t('common:videoPlayer.errorGeoBlock')}`);
        } else {
          errorBody.push(`(${event.name})`);
        }
        break;
    }

    this.getContainerElement().classList.add('has-error');
    this.errorHandlerCallback({ title: errorTitle, body: errorBody });
  };

  private hasPotentialAdBlocker = (streamErrorMessage) => {
    if (
      (
        streamErrorMessage
        // HTTP status code: 0 usually means the ajax call was cancelled, often due to some adblocker or strict security settings
        // that prevents some JS libraries (such as DAI) from loading. In this case we choose to not fallback to clear stream
        // and invite the user to whitelist us.
        // This fix the loophole where the user disables their adblocker, load the site and enables the adblocker before playing a video.
        && streamErrorMessage.indexOf('HTTP status code: 0') !== -1
      )
      || window.odabd
    ) {
      Logger.error('BitmovinClient: stream initialization failed', {
        error: {
          videoId: this.videoId,
          isDai: true,
          message: streamErrorMessage,
          adBlockerDetected: window.odabd,
        },
      });
      this.sendCustomConvivaLog(`Stream initialization failed, adBlockerDetected:${window.odabd}`);

      if (this.errorHandlerCallback) {
        this.errorHandlerCallback();
      }

      return true;
    }

    return false;
  };

  // Only triggered by DAI streams
  private onStreamError = (event) => {
    this.state.hasError = true;

    const streamData = event.getStreamData();
    const errorMessage = get(streamData, 'errorMessage');

    if (this.state.fallbackStreamUrl !== '') {
      if (!this.hasPotentialAdBlocker(errorMessage)) {
        Logger.info('BitmovinClient: switching to fallback stream');
        this.loadFallbackStream();
      }
    } else {
      this.sendCustomConvivaLog('DAI stream failed, no fallback stream available');
      Logger.error('BitmovinClient: fallback stream not available', {
        error: {
          videoId: this.videoId,
          isDai: this.state.isStreamDai,
          message: errorMessage,
        },
      });
    }
  };

  private loadFallbackStream = () => {
    Logger.info('BitmovinClient: loading fallback URL', { fallbackContent: this.state.fallbackStreamUrl });
    const convivaAppName = get(this.state.convivaContentMetadata, 'applicationName', 'unknown');
    const { streamProviderType } = this.state.videoStreamMetadata;

    this.sendCustomConvivaLog('DAI stream failed, using fallback stream', 'warning');
    this.sendCustomConvivaEvent('SbsStreamProviderFallback', {
      videoId: this.videoId,
      providerPlatform: `${streamProviderType}|web`,
      providerVideo: `${streamProviderType}|${this.videoId}`,
      providerVideoPlayer: `${streamProviderType}|${this.videoId}|${convivaAppName}`,
      providerVideoPlatform: `${streamProviderType}|${this.videoId}|web`,
    });

    if (this.state.isStreamDai) {
      this.playerEvents.disableDaiEvents();
      this.playerEvents.enableBitmovinAdEvents();
    }
    this.state.isStreamDai = false;

    this.loadSource(this.state.fallbackStreamUrl, true);
  };

  private onWarning = async (event) => {
    const sbsLogin = await getSbsLoginInstance();
    const isInAustralia = await sbsLogin.isInAustralia();

    const warningObject = {
      videoId: this.videoId,
      message: event.message,
      isDai: this.state.isStreamDai,
      name: event.name,
      code: event.code,
      type: event.type,
      contentTime: this.getCurrentContentPosition(),
    };

    switch (event.message) {
      // Some browser in some scenario would prevent playback from automatically start if the video is not muted
      case '1303/PLAYBACK_COULD_NOT_BE_STARTED':
        Logger.info('BitmovinClient: warning, playback could not be started');
        break;

      case '1401/NETWORK_COULD_NOT_LOAD_MANIFEST':
        Logger.warn('BitmovinClient: Received a warning', warningObject);

        if (!isInAustralia) {
          this.errorHandlerCallback({
            title: i18n.t('common:videoPlayer.errorTitle'),
            body: [`${i18n.t('common:videoPlayer.errorLoadingVideo')} ${i18n.t('common:videoPlayer.errorGeoBlock')}`],
          });
        } else {
          this.errorHandlerCallback({
            title: i18n.t('common:videoPlayer.errorTitle'),
            body: [
              `${i18n.t('common:videoPlayer.errorLoadingVideo')} ${i18n.t('common:videoPlayer.errorRestart')}`,
              i18n.t('common:videoPlayer.errorContactUs'),
            ],
          });
        }
        break;

      default:
        Logger.warn('BitmovinClient: Received a warning', warningObject);
        break;
    }

    this.sendCustomConvivaLog(`Bitmovin sent a warning during playback ${event.name}: [${event.code} / ${event.type}] ${event.message}`, 'warning');
  };

  private onAdClick = () => {
    this.player.pause();
  };

  private onAdStarted = (e) => {
    const buttons = this.adClickElement.getElementsByTagName('button');

    if (!this.state.isStreamDai && buttons.length) {
      const url = get(e, 'ad.clickThroughUrl');
      if (url) {
        this.adClickElement.classList.remove('hide');
        buttons[0].addEventListener('click', () => {
          window.open(url, '_blank').focus();
        });
      } else {
        this.adClickElement.classList.add('hide');
      }
    }

    this.toggleAdControls(true);
    this.state.isSnapback = false;
  };

  private onAdFinished = () => {
    if (!this.state.isStreamDai) {
      // For clear streams, since we don't have ad pod durations we need to work our ad count down
      // based on the start time of each ad.
      this.state.adCountdownReferenceStartTime = undefined;
    }
  };

  private onAdPlaying = (event: AdPlayingEvent) => {
    const {
      adProgressMetadata: { adBreakDuration, currentTime: currentAdTime },
      currentChapterData: { current: currentChapterIndex },
    } = event;

    this.toggleAdControls(true);

    let adCountdownReferenceStartTime;
    const streamTime = this.getStreamTime();

    if (!this.state.isLivestream) {
      // VOD, we will use the ad marker time as ad pod start time
      const markerIndex = currentChapterIndex - 1;
      const marker = get(this.state.timelineMarkers, `[${markerIndex}]`, undefined);

      if (marker && 'time' in marker) {
        adCountdownReferenceStartTime = marker ? marker.time : 0;
      }
    } else {
      // With Livestreams we don't have timeline markers to tell us what is the ad pod start time
      // so we have to calculate and store it for future use.
      if (streamTime !== null && this.state.adCountdownReferenceStartTime === undefined) {
        if (this.state.isStreamDai) {
          // DAI
          this.state.adCountdownReferenceStartTime = streamTime - currentAdTime;
        } else {
          // Clear streams don't have currentAdTime, so the reference start time is the start time of each ad.
          this.state.adCountdownReferenceStartTime = streamTime;
        }
      }
      ({ adCountdownReferenceStartTime } = this.state);
    }

    if (streamTime !== null && adCountdownReferenceStartTime !== undefined) {
      const adPodCurrentTime = streamTime - adCountdownReferenceStartTime;

      const remainingTime = adBreakDuration - adPodCurrentTime;
      this.updateAdMessage(remainingTime);
    }
  };

  private onAdBreakStarted = (event) => {
    const { adBreak } = event;
    if (!this.state.isStreamDai && adBreak?.position === 'pre') {
      this.getContainerElement().classList.add('nocast');
    }
    this.recordProgress();
  };

  private onAdBreakFinished = () => {
    this.state.adCountdownReferenceStartTime = undefined;
    this.startAdHoliday();
    this.toggleAdControls(false);
    this.updateAdMessage(0);
    this.getContainerElement().classList.remove('nocast');

    // due to ad controls being shown (not all icons are visible),
    // we might need to re-adjust the position of menus after an ad break has finished.
    this.adjustPlayerMenusPositions();
  };

  private onSeekStarted = () => {
    if (!this.state.isCasting) {
      this.container.classList.add('buffering');
    }
  };

  private onSeekFinished = () => {
    const currentChapter = this.getChapterData(this.playerEvents.state.positionAtSeek);
    const targetChapter = this.getChapterData(this.playerEvents.state.seekTargetTime);
    this.state.isSeekingWithinChapter = currentChapter.current === targetChapter.current;

    if (this.playerEvents.state.seekIssuer === 'snapForward') {
      this.playerEvents.dispatchEvent('SnapBackFinished', {});
    } else {
      this.snapBack();
    }

    this.playerEvents.resetSeekTargetTime();
    this.container.classList.remove('buffering');
  };

  private snapForward = () => {
    const streamTime = this.getStreamTime();
    const snapForwardTime = get(this.state, 'snapForwardTime', 0);

    if (
      snapForwardTime > 0
      && snapForwardTime > streamTime
    ) {
      Logger.info(`BitmovinClient: snapping forward to stream time ${snapForwardTime}`);

      const seekResponse = this.player.seek(snapForwardTime, 'snapForward');

      if (seekResponse === false) {
        Logger.info('BitmovinClient: snap forward failed');
      } else {
        setTimeout(() => {
          if (
            this.playerEvents.state.isSeeking === true
            && this.playerEvents.state.seekIssuer === 'snapForward'
          ) {
            Logger.info('BitmovinClient: Snap forward did not work, trying again');
            this.player.seek(snapForwardTime, 'snapForward');
          }
        }, 5000);
      }

      this.state.snapForwardTime = 0;
    }

    this.playerEvents.off('AdBreakFinished', this.snapForward);
  };

  public streamTimeIsAd = (streamTime: number) => {
    let isAd = false;

    for (let ci = 0; ci < this.playerEvents.state.cuepoints.length; ci += 1) {
      const cuepoint = this.playerEvents.state.cuepoints[ci];
      if (cuepoint.start <= streamTime && streamTime < cuepoint.end) {
        isAd = true;
        break;
      }
    }

    return isAd;
  };

  private nextCuePointForStreamTime = (streamTime) => {
    for (let mi = 0; mi < this.state.timelineMarkers.length; mi += 1) {
      const marker = this.state.timelineMarkers[mi];
      if (marker.time >= streamTime) {
        return marker;
      }
    }

    return null;
  };

  public getSnapbackAd = () => {
    const resumePosition = this.getResumePosition();

    if (this.adSnapBackEnabled === false) {
      Logger.info('BitmovinClient: Ad snapback is disabled');
      return null;
    }

    if (this.state.isStreamDai === false) {
      Logger.info('BitmovinClient: No ad snapback on non-DAI streams');
      return null;
    }

    const { isSnapback, isSeekingWithinChapter, isCasting } = this.state;
    const { seekTargetTime } = this.playerEvents.state;

    if (isCasting) {
      Logger.info('BitmovinClient: Not snapping back while casting');
      return null;
    }

    if (isSnapback) {
      Logger.info('BitmovinClient: Not snapping back as one already in progress');
      return null;
    }

    if (seekTargetTime < this.playerEvents.state.positionAtSeek) {
      Logger.info(`BitmovinClient: Not snapping back when scrubbing backwards (${seekTargetTime} < ${this.playerEvents.state.positionAtSeek})`);
      return null;
    }

    if (isSeekingWithinChapter) {
      Logger.info('BitmovinClient: Not snapping back when seeking inside the same chapter');
      return null;
    }

    const nextMarker = this.nextCuePointForStreamTime(seekTargetTime);
    const nextMarkerTime = get(nextMarker, 'time', 0);
    const scrubToMarker = Math.abs(nextMarkerTime - seekTargetTime) < 0.2;

    if (scrubToMarker) {
      Logger.info('BitmovinClient: Not snapping back as scrubbed into the start of an ad break');
      return null;
    }

    const previousAd = this.googleDai.streamManager.previousCuePointForStreamTime(seekTargetTime);
    if (previousAd.start < resumePosition) {
      Logger.info('BitmovinClient: Not snapping back as previous ads is before resume position');
      return null;
    }

    const previousAdPlayed = get(previousAd, 'played', true);
    if (previousAdPlayed) {
      Logger.info('BitmovinClient: Not snapping back as previous ads already played');
      return null;
    }

    if (this.state.isAdHoliday === true) {
      Logger.info('BitmovinClient: Ad holiday is on, skipping snapback');

      const event = new CustomEvent(
        'adHolidaySkipSnapback',
        {
          detail: {
            currentChapterData: this.getChapterData(),
          },
        },
      );
      this.container.dispatchEvent(event);
      return null;
    }

    return previousAd;
  };

  private snapBack = () => {
    const { seekTargetTime } = this.playerEvents.state;

    const previousAd = this.getSnapbackAd();

    if (previousAd !== null) {
      Logger.info('BitmovinClient: ad snapback');
      this.state.isSnapback = true;

      if (seekTargetTime > previousAd.end) {
        Logger.info(`BitmovinClient: setting snap forward time to ${seekTargetTime}`);
        this.state.snapForwardTime = seekTargetTime;

        this.playerEvents.on('AdBreakFinished', this.snapForward);
      }

      this.playerEvents.dispatchEvent('SnapBackStarted', { position: this.getStreamTime() });

      // Seek to a little before the previous ad break
      // This will allow the stream to naturally fall into the break
      // to make sure DAI send the ad start event
      this.player.seek(previousAd.start, 'snapBack');

      setTimeout(() => {
        if (
          this.playerEvents.state.isSeeking === true
          && this.playerEvents.state.seekIssuer === 'snapBack'
        ) {
          Logger.info('BitmovinClient: Snap back did not work, trying again');
          this.player.seek(previousAd.start, 'snapBack');
        }
      }, 5000);
    } else if (this.state.isSnapback === true) {
      this.state.isSnapback = false;
      // Checking if the seek target time is in the middle of an ad
      const isAd = this.streamTimeIsAd(seekTargetTime);
      this.toggleAdControls(isAd);
    }
  };

  /**
   * Player Paused event handler
   */
  // eslint-disable-next-line class-methods-use-this
  private onPaused = () => {
    const playbackToggleElement = (document.querySelector('.bmpui-ui-playbacktoggle-overlay') as HTMLElement);
    if (playbackToggleElement) {
      playbackToggleElement.style.display = 'block';
    }

    this.recordProgress();
    this.container.classList.remove('buffering');
  };

  /**
   * Reset upon unloading of player
   */
  public unload = (callback: () => void = null) => {
    Logger.info('BitmovinClient: Unloading...');
    if (this.state.unloading === false) {
      this.state.unloading = true;

      if (this.player) {
        this.player.unload();
      }

      if (this.playerKeyboardControl) {
        this.playerKeyboardControl.disable();
      }

      if (this.castPlayerController) {
        this.castPlayerController.removeEventListener(window.cast.framework.RemotePlayerEventType.MEDIA_INFO_CHANGED, this.onCastMediaInfoChanged);
        this.castPlayerController.removeEventListener(window.cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED, this.onCastPlayerStateChanged);
        // @ts-ignore unresolved variable IS_PLAYING_BREAK_CHANGED
        this.castPlayerController.removeEventListener(cast.framework.RemotePlayerEventType.IS_PLAYING_BREAK_CHANGED, this.onCastIsPlayingBreakChanged);
      }

      for (let ti = 0; ti < this.preUnloadTasks.length; ti += 1) {
        const taskCallback = this.preUnloadTasks[ti];
        taskCallback();
      }

      this.preUnloadTasks = [];

      if (this.playerEvents) {
        this.playerEvents.destroy();
        delete this.playerEvents;
      }

      if (this.conviva) {
        this.conviva.release();
      }

      if (this.state.recordPlayerSessionInterval) {
        clearInterval(this.state.recordPlayerSessionInterval);
      }

      for (let pi = 0; pi < this.plugins.length; pi += 1) {
        const plugin = this.plugins[pi];
        plugin.destroy();
        this.plugins[pi] = null;
      }
      this.plugins = [];

      if (this.googleDai) {
        this.googleDai.unload();
      }

      this.state.fallbackStreamUrl = null;
      this.state.timelineMarkers = [];

      if (this.uiManager) {
        this.uiManager.release();
        delete this.uiManager;
      }
    }

    delete this.player;

    if (typeof callback === 'function') {
      callback();
    }
  };

  public getChapterData = (streamTime = null): ChapterData => {
    // When you start the video from the beginning, it's chapter 1
    let currentChapter = 1;
    const currentTime = streamTime !== null ? streamTime : this.getStreamTime();

    for (let mi = 0; mi < this.state.timelineMarkers.length; mi += 1) {
      const marker = this.state.timelineMarkers[mi];

      if (currentTime >= marker.time) {
        // marker at index 0 is chapter 1
        currentChapter = mi + 1;

        // if the first marker has a time greater than 0,
        // this means from beginning to timelineMarkers[0] is chapter 1
        // between timelineMarkers[0] and timelineMarkers[1] is chapter 2
        if (this.state.timelineMarkers[0].time > 0) {
          currentChapter += 1;
        }
      }
    }

    return {
      current: currentChapter,
      total: this.state.timelineMarkers.length > 0 ? this.state.timelineMarkers.length : 1,
    };
  };

  public contentTimeForStreamTime = (streamTime) => {
    if (this.state.isStreamDai) {
      return this.googleDai.contentTimeForStreamTime(streamTime);
    }
    return streamTime;
  };

  public streamTimeForContentTime = (contentTime) => {
    if (this.state.isStreamDai) {
      return this.googleDai.streamTimeForContentTime(contentTime);
    }
    return contentTime;
  };

  private checkPreviousPlayerSession = () => {
    // No need for this as we don't support Ad Holiday for livestreams
    if (!this.state.isLivestream) {
      const playerSession: PlayerSession = ls.get('od.player.session');
      if (playerSession) {
        const { timestamp: sessionTimestamp, videoId: sessionVideoId } = playerSession;
        const previousSessionLastTimestamp = sessionTimestamp;
        const now = new Date().getTime();
        const previousSessionDeltaTime = (now - previousSessionLastTimestamp) / 1000;

        if (
          this.videoId === sessionVideoId
          && previousSessionDeltaTime <= 120
        ) {
          Logger.info('BitmovinClient: Found a recent session for this video');
          this.startAdHoliday();
        }
      }
    }
  };

  private getResumePosition = () => {
    if (this.resumePosition > 0) {
      let resumePosition = this.streamTimeForContentTime(this.resumePosition);
      Logger.info(`Resuming content to ${this.resumePosition} (${resumePosition})`);
      for (let ci = 0; ci < this.playerEvents.state.cuepoints.length; ci += 1) {
        const cuepoint = this.playerEvents.state.cuepoints[ci];

        // If the resume position is too close to the start of an ad, resume to the end of the ad
        if (Math.abs(cuepoint.start - resumePosition) < 1) {
          resumePosition = cuepoint.start - 5;
        }
      }

      return resumePosition;
    }

    return undefined;
  };

  public isContentAnEpisode = () => {
    return this.state.videoStreamMetadata.video.type === 'Episode';
  };

  /**
   * Loading a new source into Bitmovin player
   * @param url
   * @param loadSidecar
   */
  public loadSource = (url: string, loadSidecar: boolean = false) => {
    if (!url) {
      Logger.error('Source URL not available', {
        error: {
          videoId: this.videoId,
          isDai: this.state.isStreamDai,
        },
      });
      return;
    }

    // Disabling the anchor on the player logo
    const watermarkElement = document.querySelector('button.bmpui-ui-watermark') as HTMLElement;
    if (watermarkElement) {
      watermarkElement.dataset.url = '';
    }

    this.checkPreviousPlayerSession();
    const playbackResumePosition = this.getResumePosition();

    this.player.setVideoElement(this.videoElement);
    if (this.conviva) {
      this.state.convivaContentMetadata.streamUrl = url;
    }

    this.player.load({
      title: `[${this.videoId}] ${this.state.videoStreamMetadata.video.mediaTitle}`,
      hls: url,
      ...(!!playbackResumePosition && {
        options: {
          startOffset: playbackResumePosition,
        },
      }),
    }).then(() => {
      if (loadSidecar && this.state.videoStreamMetadata.subtitleSidecarFiles.length > 0) {
        for (let si = 0; si < this.state.videoStreamMetadata.subtitleSidecarFiles.length; si += 1) {
          const sideCar = this.state.videoStreamMetadata.subtitleSidecarFiles[si];
          const subtitle = {
            id: `sub${si + 1}`,
            lang: sideCar.language,
            label: sideCar.name,
            url: sideCar.url,
            kind: 'subtitle',
          };

          this.player.subtitles.add(subtitle);
        }
      }

      const controlBarElement = (document.querySelector('.bmpui-ui-controlbar') as HTMLElement);
      const playbackToggleElement = (document.querySelector('.bmpui-ui-playbacktoggle-overlay') as HTMLElement);

      /* Prevent clicks on the control buttons to reach the advert container generating an ad click */
      if (controlBarElement) {
        controlBarElement.addEventListener('click', (e) => {
          e.preventDefault();
          e.stopImmediatePropagation();
          e.stopPropagation();
        });

        /* Preventing clicks on the floating play button to reach the advert container generating an ad click */
        playbackToggleElement.addEventListener('click', (e) => {
          e.preventDefault();
          e.stopImmediatePropagation();
          e.stopPropagation();
        });
      }

      this.container.classList.add('source-loaded');
    });
  };

  private preparePlayerConfigForChromecast = (config) => {
    if (CHROMECAST_RCV_APP_ID) {
      Logger.info(`BitmovinClient: Configure casting for ${CHROMECAST_RCV_APP_ID}`);

      return {
        ...config,
        cast: {
          enable: true,
        },
        remotecontrol: {
          type: 'googlecast',
          receiverApplicationId: CHROMECAST_RCV_APP_ID,
          receiverVersion: 'v3',
          rejoinActiveSession: true,
          messageNamespace: 'urn:x-cast:com.google.cast.media',
          prepareMediaInfo: (mediaInfo) => {
            const { castMetadata } = this.options;

            const {
              oztamMetadata, convivaAssetMetadata, adTag, contentUrl, fallbackContentUrl,
              providerAccountId, streamProviderType, rawTrackingEvents, rawVideo, rawNextVideo,
              chromecast, videoId,
            } = this.state.videoStreamMetadata;
            const newMediaInfo = {
              ...mediaInfo,
              entity: `ondemand://casting/${this.videoId}`,
              ...(this.state.isStreamDai && { contentId: this.videoId }),
              ...(!this.state.isStreamDai && { contentUrl }),
              customData: {
                ...mediaInfo.customData,

                // Only output videoId if it's not a DAI assetKey which would be different from the MPX ID (this.videoId)
                ...(!this.state.isLivestream && { videoId }),

                ...(castMetadata.customData && {
                  ...castMetadata.customData,
                }),
                trackingEvents: rawTrackingEvents,
                videoItem: rawVideo,
                ...(rawNextVideo && { nextVideoItem: rawNextVideo }),
                metadata: {
                  title: chromecast.receiver.metadata.title,
                  subtitle: chromecast.receiver.metadata.subtitle,
                  images: [
                    {
                      url: chromecast.receiver.metadata.image,
                      width: chromecast.receiver.metadata.image_width,
                      height: chromecast.receiver.metadata.image_height,
                    },
                  ],
                  metadataType: 1,
                },
                oztam: {
                  ...oztamMetadata,
                },
                conviva: {
                  ...convivaAssetMetadata,
                },
                ...(streamProviderType === 'GoogleDAIProvider' && {
                  contentSourceId: providerAccountId,
                  fallbackUrl: fallbackContentUrl,
                }),
                ...(this.state.isLivestream && {
                  assetKey: videoId,
                }),
                adTagParameters: {
                  idtype: 'idfa',
                  is_lat: '1',
                  ppid: adTag.ppid,
                  description_url: adTag.description_url,
                  iu: adTag.iu,
                  cust_params: adTag.cust_params,
                },
                ...(streamProviderType === 'AkamaiHLSProvider' && {
                  contentUrl,
                  fallbackUrl: contentUrl,
                }),
                startTime: this.state.castStartTime,
              },
              metadata: {
                title: chromecast.receiver.metadata.title,
                subtitle: chromecast.receiver.metadata.subtitle,
                images: [
                  {
                    url: chromecast.receiver.metadata.image,
                    width: chromecast.receiver.metadata.image_width,
                    height: chromecast.receiver.metadata.image_height,
                  },
                ],
                metadataType: 1,
              },
            };

            if (!this.state.isStreamDai) {
              delete newMediaInfo.contentId;
            }

            return Promise.resolve(newMediaInfo);
          },
        },
      };
    }

    return {
      ...config,
      cast: {
        enable: false,
      },
    };
  };

  public on = (event: string, callback) => {
    if (this.playerEvents) {
      this.playerEvents.on(event, callback);
    }
  };

  public off = (event: string, callback) => {
    if (this.playerEvents) {
      this.playerEvents.off(event, callback);
    }
  };

  public getSelectedSubtitles = () => {
    if (this.player.subtitles) {
      return this.player.subtitles.list().filter((subtitle) => { return subtitle.enabled; }).pop();
    }

    return null;
  };

  public getSelectedAudio = () => {
    return this.player.getAudio();
  };

  public getUserSettings = (): PlayerUserSettings => {
    const selectedSubtitles = this.getSelectedSubtitles();
    const selectedAudio = this.getSelectedAudio();

    return {
      subtitleLanguage: get(selectedSubtitles, 'lang', 'off'),
      subtitleLabel: get(selectedSubtitles, 'label', ''),
      audioLanguage: get(selectedAudio, 'lang', ''),
      audioLabel: get(selectedAudio, 'label', ''),
    };
  };
}

export default BitmovinClient;
