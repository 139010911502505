import VideoPlayer from '@@src/lib/VideoPlayerV2/VideoPlayer';
import {
  VideoPlayerEventBase,
  VideoPlayerEventCallback,
  VideoPlayerEventType,
  VideoPlayerSeekEvent,
} from '@@src/lib/VideoPlayerV2/VideoPlayerEventManager';
import OnDemand from '@@types/OnDemand';

interface PlaybackEvents {
  type: VideoPlayerEventType;
  handler: VideoPlayerEventCallback;
}

class SaveProgressCore {
  private recordCallback: (duration: number, position: number, id: string) => void;
  private video: OnDemand.Video;
  private videoPlayer: VideoPlayer;
  private events: PlaybackEvents[] = [];
  // @ts-ignore, no need to set until the interval is created
  private autoSaveInterval: ReturnType<typeof setInterval>;

  public constructor(
    videoPlayer: VideoPlayer,
    video: OnDemand.Video,
    recordCallback: (duration: number, position: number, id: string) => void,
  ) {
    this.videoPlayer = videoPlayer;
    this.video = video;
    this.recordCallback = recordCallback;

    this.events = [
      { type: VideoPlayerEventType.CONTENT_STARTED, handler: this.onContentStarted as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.AD_BREAK_STARTED, handler: this.onAdBreakStarted as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.AD_BREAK_FINISHED, handler: this.onAdBreakFinished as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.PLAY, handler: this.onPlay as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.PAUSED, handler: this.onPaused as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.PLAYBACK_FINISHED, handler: this.onPlaybackFinished as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.SEEK_STARTED, handler: this.onSeekStarted as VideoPlayerEventCallback },
    ];
    this.registerEvents();
  }

  private registerEvents = () => {
    this.events.forEach((event) => {
      this.videoPlayer.on(event.type, event.handler);
    });
  };

  private unRegisterEvents = () => {
    this.events.forEach((event) => {
      this.videoPlayer.off(event.type, event.handler);
    });
  };

  private record = (overridenPosition?: number) => {
    const positionToSave = overridenPosition || this.videoPlayer.getCurrentContentTime();
    this.recordCallback(this.video.duration, positionToSave, this.video.id);
  };

  private enableAutoSave = () => {
    clearInterval(this.autoSaveInterval);
    this.autoSaveInterval = setInterval(() => {
      this.record();
    }, 90000);
  };

  private disableAutoSave = () => {
    clearInterval(this.autoSaveInterval);
  };

  private onContentStarted = () => {
    this.record();
    this.enableAutoSave();
  };

  private onAdBreakStarted = () => {
    this.disableAutoSave();
  };

  private onAdBreakFinished = () => {
    this.enableAutoSave();
  };

  private onPlay = () => {
    this.enableAutoSave();
  };

  private onPaused = (event: VideoPlayerEventBase) => {
    const { issuer } = event;

    if (issuer?.startsWith('ui') || issuer === 'playerclose') {
      this.record();
    }

    this.disableAutoSave();
  };

  private onPlaybackFinished = () => {
    this.record();
    this.disableAutoSave();
  };

  private onSeekStarted = (event: VideoPlayerSeekEvent) => {
    const { issuer } = event;

    if (issuer?.startsWith('ui')) {
      this.record(this.videoPlayer.contentTimeForStreamTime(event.seekTarget));
    }
  };

  public destroy = () => {
    this.disableAutoSave();
    this.unRegisterEvents();
  };
}

export default SaveProgressCore;
