/**
 * Overrides Bitmovin's AudioTrackUtils class
 */
import { UIInstanceManager } from '@sbs/bitmovin-player-ui';
import { ListItem, ListSelector, ListSelectorConfig } from '@sbs/bitmovin-player-ui/dist/js/framework/components/listselector';
import { PlayerAPI, AudioTrackEvent, AudioTrack, PlayerEvent, PlayerEventCallback } from 'bitmovin-player';

import { getUserPreferences } from '@@src/lib/VideoPlayerV2/plugins/SaveUserPreferences/SaveUserPreferences';

/**
 * Helper class to handle all audio tracks related events
 *
 * This class listens to player events as well as the `ListSelector` event if selection changed
 */
// following BitmovinUI repo structure
// eslint-disable-next-line import/prefer-default-export
export class OdAudioTrackSwitchHandler {
  private player: PlayerAPI;
  private listElement: ListSelector<ListSelectorConfig>;
  private uimanager: UIInstanceManager;

  constructor(player: PlayerAPI, element: ListSelector<ListSelectorConfig>, uimanager: UIInstanceManager) {
    this.player = player;
    this.listElement = element;
    this.uimanager = uimanager;

    this.bindSelectionEvent();
    this.bindPlayerEvents();
    this.refreshAudioTracks();
  }

  private bindSelectionEvent(): void {
    this.listElement.onItemSelected.subscribe((_, value: string) => {
      this.player.setAudio(value);
    });
  }

  private bindPlayerEvents(): void {
    // Update selection when selected track has changed
    this.player.on(PlayerEvent.AudioChanged, this.selectCurrentAudioTrack);
    // Update tracks when source goes away
    this.player.on(PlayerEvent.SourceUnloaded, this.refreshAudioTracks);
    // Update tracks when the period within a source changes
    this.player.on(PlayerEvent.PeriodSwitched, this.refreshAudioTracks);
    // Update tracks when a track is added or removed
    this.player.on(PlayerEvent.AudioAdded, this.addAudioTrack as PlayerEventCallback);
    this.player.on(PlayerEvent.AudioRemoved, this.removeAudioTrack as PlayerEventCallback);
    this.player.on(PlayerEvent.Ready, this.setAudioTrackOnReady);
    this.uimanager.getConfig().events.onUpdated.subscribe(this.refreshAudioTracks);
  }

  private addAudioTrack = (event: AudioTrackEvent) => {
    const audioTrack = event.track;

    if (!this.listElement.hasItem(audioTrack.id)) {
      this.listElement.addItem(audioTrack.id, audioTrack.label);

      const currentAudioTrack = this.player.getAudio();
      if (currentAudioTrack && audioTrack.id === currentAudioTrack.id) {
        this.listElement.selectItem(currentAudioTrack.id);
      }
    }
  };

  private removeAudioTrack = (event: AudioTrackEvent) => {
    const audioTrack = event.track;
    if (this.listElement.hasItem(audioTrack.id)) {
      this.listElement.removeItem(audioTrack.id);
    }
  };

  private selectCurrentAudioTrack = () => {
    const currentAudioTrack = this.player.getAudio();

    // HLS streams don't always provide this, so we have to check
    if (currentAudioTrack) {
      this.listElement.selectItem(currentAudioTrack.id);
    }
  };

  private setAudioTrackOnReady = () => {
    const userPrefs = getUserPreferences();

    let matchedAudioTrack;

    // Match by label
    if (userPrefs?.audioLabel) {
      matchedAudioTrack = this.player.getAvailableAudio().filter((audioTrack) => {
        return userPrefs.audioLabel === audioTrack.label;
      }).pop();
    }

    // Fallback to matching by language code
    if (!matchedAudioTrack && userPrefs?.audioLanguage) {
      matchedAudioTrack = this.player.getAvailableAudio().filter((audioTrack) => {
        return userPrefs.audioLanguage === audioTrack.lang;
      }).shift();
    }

    if (matchedAudioTrack) {
      this.player.setAudio(matchedAudioTrack.id);
    }
  };

  private refreshAudioTracks = () => {
    const audioTracks = this.player.getAvailableAudio();
    audioTracks.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }

      if (a.label > b.label) {
        return 1;
      }

      return 0;
    });

    if (audioTracks.length > 0) {
      const audioTrackToListItem = (audioTrack: AudioTrack): ListItem => {
        return { key: audioTrack.id, label: audioTrack.label };
      };

      this.listElement.synchronizeItems(audioTracks.map(audioTrackToListItem));
    } else {
      // no audio tracks available
      this.listElement.synchronizeItems([]);
    }

    this.selectCurrentAudioTrack();
  };
}
