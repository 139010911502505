import { FunctionComponent, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@@src/hooks/store';
import { VideoPlayerPluginProps } from '@@src/lib/VideoPlayerV2/plugins/VideoPlayerPlugin';
import { getRecommendation, clearMatchingRecommendation } from '@@stores/RecommendationStore';
import { generateRecommendationTrackingData } from '@@utils/Recommendations';

import VideoPlaybackTrackingCore from './VideoPlaybackTrackingCore';

const VideoPlaybackTracking: FunctionComponent<VideoPlayerPluginProps> = (props) => {
  const { videoPlayer, playbackStreamData, video } = props;
  const dispatch = useAppDispatch();
  const recommendation = useAppSelector(getRecommendation);

  useEffect(() => {
    if (video) {
      let _video = video;

      if (!video.cdpTitle) {
        _video = {
          ...video,
          cdpTitle: playbackStreamData.cdpTitle,
        };
      }

      const {
        personalisation,
        recommendationTargetId,
      } = generateRecommendationTrackingData(recommendation, video);
      const tracker = new VideoPlaybackTrackingCore(videoPlayer, _video, personalisation);

      return () => {
        dispatch(clearMatchingRecommendation({ targetId: recommendationTargetId }));
        tracker.destroy();
      };
    }

    return () => {};
  }, [videoPlayer, playbackStreamData, video, recommendation, dispatch]);

  // This plugin does not have a UI
  return null;
};

export default VideoPlaybackTracking;
