import VideoPlayer from '@@src/lib/VideoPlayerV2/VideoPlayer';
import {
  VideoPlayerEvent,
  VideoPlayerEventCallback,
  VideoPlayerEventType, VideoPlayerPlaybackEvent,
} from '@@src/lib/VideoPlayerV2/VideoPlayerEventManager';
import OnDemand from '@@types/OnDemand';
import DataLayer, { PersonalisationData } from '@@utils/DataLayer';

interface PlaybackEvents {
  type: VideoPlayerEventType;
  handler: VideoPlayerEventCallback;
}

class VideoPlaybackTrackingCore {
  private video: OnDemand.Video;
  private videoPlayer: VideoPlayer;
  private events: PlaybackEvents[] = [];
  private supportedMilestones = [25, 50, 75, 95];
  private supportedMilestonesPositions: number[] = [];
  private currentMilestone = 0;
  private personalisation: PersonalisationData | undefined;

  public constructor(videoPlayer: VideoPlayer, video: OnDemand.Video, personalisation?: PersonalisationData) {
    this.videoPlayer = videoPlayer;
    this.personalisation = personalisation;
    this.video = video;
    this.events = [
      { type: VideoPlayerEventType.SOURCE_LOADED, handler: this.onSourceLoaded },
      { type: VideoPlayerEventType.CONTENT_STARTED, handler: this.onContentStarted as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.AD_BREAK_STARTED, handler: this.onAdBreakStarted as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.AD_BREAK_FINISHED, handler: this.onAdBreakFinished as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.TIME_CHANGED, handler: this.onTimeChanged as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.SUBTITLE_ENABLE, handler: this.onSubtitleEnabled as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.SUBTITLE_DISABLE, handler: this.onSubtitleDisabled as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.AUDIO_CHANGED, handler: this.onAudioChanged as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.PAUSED, handler: this.onPaused as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.PLAYBACK_FINISHED, handler: this.onPlaybackFinished as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.AD_STARTED, handler: this.onAdStarted as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.AD_FINISHED, handler: this.onAdFinished as VideoPlayerEventCallback },
    ];
    this.registerEvents();
  }

  private sendDataLayerVideoTracking = (actionName: string, additionalAttributes = {}) => {
    DataLayer.updatePlayerInfo(this.videoPlayer.getPlayerMetadata(), this.videoPlayer.getUserSettings());
    const currentChapterData = this.videoPlayer.getCurrentChapterData();
    DataLayer.events.videoTrackingEvent(
      actionName,
      this.video,
      {
        ...additionalAttributes,
        ...(currentChapterData && { currentChapterData }),
        milestone: this.currentMilestone,
        personalisation: this.personalisation,
      },
    );
  };

  private onSourceLoaded = () => {
    this.sendDataLayerVideoTracking('videoLoaded');

    const videoDuration = this.video.duration || 0;
    this.supportedMilestones.forEach((milestone) => {
      const milestoneInSeconds = (milestone / 100) * videoDuration;
      this.supportedMilestonesPositions.push(milestoneInSeconds);
    });
  };

  private onAdBreakStarted = () => {
    this.checkMilestone();
  };

  private onAdBreakFinished = () => {
    const currentChapterData = this.videoPlayer.getCurrentChapterData();
    if (currentChapterData.current > 1) {
      this.sendDataLayerVideoTracking('videoChapterBreak');
    }
  };

  private getMilestone(contentTime: number): { value: number, delta: number } {
    let milestoneReached = 0;
    let milestoneDelta = 0;

    this.supportedMilestonesPositions.forEach((milestonePosition, index) => {
      if (contentTime >= milestonePosition) {
        milestoneReached = this.supportedMilestones[index];
        milestoneDelta = Math.abs(milestonePosition - contentTime);
      }
    });

    return {
      value: milestoneReached,
      delta: milestoneDelta,
    };
  }

  private checkMilestone = () => {
    const contentTime = this.videoPlayer.getCurrentContentTime();
    const milestoneData = this.getMilestone(contentTime);

    if (milestoneData.value > this.currentMilestone) {
      this.currentMilestone = milestoneData.value;

      if (milestoneData.delta <= 0.2) {
        this.sendDataLayerVideoTracking(`videoMilestone${milestoneData.value}`);
      }
    }
  };

  private onTimeChanged = () => {
    this.checkMilestone();
  };

  private onContentStarted = () => {
    this.checkMilestone();
    this.sendDataLayerVideoTracking('videoStarted');
  };

  private onSubtitleEnabled = () => {
    this.sendDataLayerVideoTracking(
      'videoSubtitle_On',
      {
        videoPlayerEvent: {
          videoSubtitle: 'On',
        },
      },
    );
  };

  private onSubtitleDisabled = () => {
    this.sendDataLayerVideoTracking(
      'videoSubtitle_Off',
      {
        videoPlayerEvent: {
          videoSubtitle: 'Off',
        },
      },
    );
  };

  private onAudioChanged = () => {
    this.sendDataLayerVideoTracking(
      'videoAudioTrack_On',
      {
        videoPlayerEvent: {
          videoAudioTrack: 'On',
        },
      },
    );
  };

  private onPaused = (event: VideoPlayerPlaybackEvent) => {
    if (event.issuer !== 'ui-seek') {
      this.sendDataLayerVideoTracking('videoPause');
    }
  };

  private onAdStarted = (event: VideoPlayerEvent<VideoPlayerEventType.AD_STARTED>) => {
    const { adMetadata } = event;
    this.sendDataLayerVideoTracking('videoAdStart', { adMetadata });
  };

  private onAdFinished = (event: VideoPlayerEvent<VideoPlayerEventType.AD_FINISHED>) => {
    const { adMetadata } = event;
    this.sendDataLayerVideoTracking('videoAdComplete', { adMetadata });
  };

  private onPlaybackFinished = () => {
    this.sendDataLayerVideoTracking('videoCompleted');
  };

  private registerEvents = () => {
    this.events.forEach((event) => {
      this.videoPlayer.on(event.type, event.handler);
    });
  };

  private unRegisterEvents = () => {
    this.events.forEach((event) => {
      this.videoPlayer.off(event.type, event.handler);
    });
  };

  public destroy = () => {
    this.unRegisterEvents();
  };
}

export default VideoPlaybackTrackingCore;
