import OnDemand from '@@types/OnDemand';

/**
 * Finds and return the first image matching the types in order
 * @param images
 * @param types
 */
export const getImageByType = (images: OnDemand.VideoImages, types: string[]): string => {
  if (!images) {
    return '';
  }

  let imageUrl = '';

  types.every((type) => {
    Object.keys(images).every((key) => {
      if (key === type) {
        imageUrl = images[key];
      }

      return !imageUrl;
    });

    return !imageUrl;
  });

  return imageUrl;
};

export default {
  getImageByType,
};
